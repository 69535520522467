import { Button, Card } from 'antd';

import { useGetDeploymentReleaseStatus } from '../../../hooks/deployments/deployments';

// 'pending' | 'deployed' | 'error' | 'not-deleted' | 'deploying' | 'deleted' | 'cleanup' | 'warning'
export function DeploymentStatus({ release }) {
  const release$ = useGetDeploymentReleaseStatus(release);

  return (
    <Card
      className="text-xs border-gray-300"
      title="Release status"
      bodyStyle={{ padding: 0 }}
      loading={release$.isLoading || release$.isRefetching}
      extra={<Button onClick={release$.refetch}>Refresh</Button>}
    >
      <pre className="bg-gray-100 p-3">
        <code>{JSON.stringify(release$.data, null, 2)}</code>
      </pre>
    </Card>
  );
}

import Editor from '@monaco-editor/react';
import { Spin } from 'antd';
import { useGetInspectRelease } from '../../../hooks/deployments/deployments';

export function DeploymentInfo({ release = {} }) {
  const values$ = useGetInspectRelease(release.id, { enabled: !!release.id });

  return (
    <Spin spinning={values$.isLoading || false}>
      <Editor height="1100px" theme="vs-dark" language="yaml" value={values$.data?.data || ''} />
    </Spin>
  );
}

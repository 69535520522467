import { Col, Descriptions, Row } from 'antd';
import { LinkOutlined, MailOutlined } from '@ant-design/icons';

import { capitalize } from 'lodash';

export function NewOrganizationSummary({ organizationData = {} }) {
  console.debug('organizationData', organizationData);
  return (
    <div>
      <Row className="mb-5" gutter={16}>
        <Col span={24}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item span={3}>
              <b>General Information</b>
            </Descriptions.Item>
            <Descriptions.Item label="Organization Name">
              {organizationData.general.name}
            </Descriptions.Item>
            <Descriptions.Item label="Owner">
              {organizationData.general.ownerName}
            </Descriptions.Item>
            <Descriptions.Item
              label="Contact 
            Email"
            >
              <a
                className="inline-flex items-center text-blue-500"
                referrerPolicy="no-referrer"
                target="_blank"
                href={`mailto:${organizationData.general.ownerEmail}`}
                rel="noreferrer"
              >
                <MailOutlined className="mr-2" />
                {organizationData.general.ownerEmail}
              </a>
            </Descriptions.Item>

            <Descriptions.Item label="Language">
              {organizationData.general.organizationLanguage}
            </Descriptions.Item>
            <Descriptions.Item label="Home Country">
              {organizationData.general.homeCountry}
            </Descriptions.Item>
            <Descriptions.Item label="Created at">-</Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>
              {organizationData.general.description}
            </Descriptions.Item>
            <Descriptions.Item span={3}>
              <b>Deployment Information</b>
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <span className="capitalize inline-flex items-center">Ready to Deploy</span>
            </Descriptions.Item>
            <Descriptions.Item label="Chart">{organizationData.deployment.chart}</Descriptions.Item>
            <Descriptions.Item label="Domain">
              <a
                className="inline-flex items-center text-blue-500"
                referrerPolicy="no-referrer"
                target="_blank"
                href={`//${organizationData.general.subdomain}.${organizationData.general.domain}`}
                rel="noreferrer"
              >
                <LinkOutlined className="mr-2" />
                {organizationData.general.subdomain}.{organizationData.general.domain}
              </a>
            </Descriptions.Item>

            <Descriptions.Item label="Docker image">
              {organizationData.deployment.image}
            </Descriptions.Item>
            <Descriptions.Item label="Image tag">
              {organizationData.deployment.appVersion}
            </Descriptions.Item>
            <Descriptions.Item label=""></Descriptions.Item>
            <Descriptions.Item span={3}>
              <b>Database Information</b>
            </Descriptions.Item>
            <Descriptions.Item label="Use Own Database">
              {organizationData.database.useOwnDb ? 'Yes' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Db mode">
              {capitalize(organizationData.database.dbMode)}
            </Descriptions.Item>
            <Descriptions.Item label="Db type">
              {capitalize(organizationData.database.dbType)}
            </Descriptions.Item>
            <Descriptions.Item label="Username">
              {organizationData.database?.dbUsername || (
                <span className="text-xs text-gray-500">Auto-generated</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Host">
              {organizationData.database?.dbHost || (
                <span className="text-xs text-gray-500">Auto-generated</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label=""></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
}

import './index.css';

import { ConfigProvider, Spin } from 'antd';

import { ActivitiesViewProvider } from './components/activity-fills';
import { Auth } from './containers/Auth';
import { Auth0Provider } from '@auth0/auth0-react';
import { PermissionsProvider } from './context/permissions';
import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import { router } from './router';

function App() {
  const auth0Domain = process.env.REACT_APP_AUTH0_CLIENT_DOMAIN || '';
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE || 'read:current_user';

  const auth0Config = {
    cacheLocation: 'memory',
    domain: auth0Domain,
    clientId: auth0ClientId,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: auth0Audience,
      scope: auth0Scope,
    },
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0f9883',
          colorBgContainer: '#ffffff',
        },
        components: {
          Menu: {
            darkItemBg: '#3B3B3B',
            darkSubMenuItemBg: '#3B3B3B',
          },
          Layout: {
            headerBg: '#3B3B3B',
            siderBg: '#3B3B3B',
            triggerBg: '#3B3B3B',
            colorText: '#ffffff',
            footerBg: '#3B3B3B',
          },
        },
      }}
    >
      <ActivitiesViewProvider>
        <Auth0Provider {...auth0Config}>
          <Auth>
            <PermissionsProvider>
              <Suspense fallback={<Spin />}>
                <RouterProvider router={router} />
              </Suspense>
            </PermissionsProvider>
          </Auth>
        </Auth0Provider>
      </ActivitiesViewProvider>
    </ConfigProvider>
  );
}

export default App;

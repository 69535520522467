import { Avatar, Card } from 'antd';
import moment from 'moment';

export function CommonActivityView({ user, scope, action, createdAt, extra = {} }) {
  return (
    <>
      <Card bordered={true} className="w-full">
        <Card.Meta
          description={
            <>
              <div>
                <strong>{user?.name || 'System'}</strong> call {scope}:{action}.{' '}
                {extra?.name ? (
                  <>
                    Target: <strong>{extra?.name}</strong>
                  </>
                ) : null}
              </div>
              {createdAt ? <p className="text-xs">{moment(createdAt).format('L LT')}</p> : null}
            </>
          }
          avatar={<Avatar src={user?.avatar}>{user?.name || 'System'}</Avatar>}
        />
      </Card>
    </>
  );
}

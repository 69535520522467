import { forwardRef } from 'react';
import { Input, Form, Col, Row } from 'antd';

export const RepositoryForm = forwardRef(function RepositoryForm(props, ref) {
  return (
    <Form ref={ref} layout="vertical">
      <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
        <Input placeholder="bitnami" />
      </Form.Item>
      <Form.Item
        label="Repository url"
        name="url"
        required
        rules={[{ required: true, type: 'url' }]}
      >
        <Input placeholder="https://charts.bitnami.com/bitnami" />
      </Form.Item>
      <fieldset>
        <legend>Credentials</legend>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Username" name="username">
              <Input placeholder="john.doe" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Password" name="password">
              <Input placeholder="Password" type="password" />
            </Form.Item>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
});

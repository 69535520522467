export const StatusComponent = ({ status }) => {
  return (
    <span
      className={`h-5 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
        status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
      }`}
    >
      {status?.toUpperCase()}
    </span>
  );
};

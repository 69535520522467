import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Slider,
  Spin,
  message,
} from 'antd';
import _, { isNull } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  useGetOrganizationDeploymentValues,
  useScaleOrganization,
} from '../../../../hooks/organizations/organizations-management';

import { InfoCircleOutlined } from '@ant-design/icons';
import { unflatten } from 'flat';

function marks(count) {
  return _.range(count + 1).reduce((acc, num) => ({ ...acc, [num]: num }), {});
}

function AutoScalingForm({ form, values = [] }) {
  const onChange = ([min, max]) =>
    form.setFieldsValue({ 'autoscaling.maxReplicas': max, 'autoscaling.minReplicas': min });

  return (
    <>
      <fieldset>
        <div className="flex flex-row items-center text-gray-500 mb-2">
          <InfoCircleOutlined className="cursor-pointer mr-2 text-blue-500" />
          <span className="text-lg">Auto-scaling settings</span>
        </div>

        <Form.Item hidden name="autoscaling.enabled" initialValue={true} />
        <Form.Item hidden name="autoscaling.maxReplicas" rules={[{ type: 'integer' }]} />
        <Form.Item hidden name="autoscaling.minReplicas" rules={[{ type: 'integer' }]} />
        <Form.Item label="Min/max replicas for autoscaling" className="overflow-visible">
          <Slider
            dots
            range
            min={1}
            max={10}
            autoFocus
            marks={marks(10)}
            onChange={onChange}
            defaultValue={values}
          />
        </Form.Item>
      </fieldset>
    </>
  );
}

function StaticScalingForm({ size }) {
  return (
    <Row gutter={16}>
      <Col span={12}>
        <fieldset>
          <div className="flex flex-row items-center text-gray-500 mb-2">
            <InfoCircleOutlined className="cursor-pointer mr-2 text-blue-500" />
            <span className="text-lg">Scaling</span>
          </div>
          <Form.Item
            initialValue={1}
            label="Replicas"
            name="replicaCount"
            extra="Number of simultaneously running instances"
          >
            <InputNumber className="w-full" min={1} step={1} />
          </Form.Item>
        </fieldset>
      </Col>
    </Row>
  );
}

export function AppScaling({ size = 'large', organization = {} }) {
  const [cpu, setCpu] = useState();
  const [ram, setRam] = useState();
  const [autoscaling, setAutoscaling] = useState(false);
  const values$ = useGetOrganizationDeploymentValues(organization.id, {
    enabled: !!organization.id,
  });

  const form = useRef();
  const scale$ = useScaleOrganization();

  useEffect(() => {
    if (values$.data) {
      const { data = {} } = values$.data;

      setAutoscaling(data.autoscaling?.enabled);
      form.current?.setFieldsValue({
        replicaCount: data.replicaCount || 1,
        'resources.limits.cpu': parseInt(data.resources?.limits?.cpu || 0),
        'resources.limits.memory': parseInt(data.resources?.limits?.memory || 0),

        'resources.requests.cpu': parseInt(data.resources?.requests?.cpu || 0),
        'resources.requests.memory': parseInt(data.resources?.requests?.memory || 0),

        'autoscaling.minReplicas': parseInt(data.resources?.autoscaling?.minReplicas || 1),
        'autoscaling.maxReplicas': parseInt(data.resources?.autoscaling?.maxReplicas || 2),
      });
    }
  }, [values$.data]);

  const onFormSubmit = (values) => {
    if (!ram || !cpu) {
      message.error('Please select resource units...');
      return;
    }
    const payload = unflatten(values);
    payload.organization = organization.id;

    payload.resources.limits.memory = `${payload.resources.limits.memory}${ram}`;
    payload.resources.requests.memory = `${payload.resources.requests.memory}${ram}`;

    payload.resources.limits.cpu = `${payload.resources.limits.cpu}${cpu}`;
    payload.resources.requests.cpu = `${payload.resources.requests.cpu}${cpu}`;

    return scale$.mutateAsync(payload);
  };

  if (!values$.isFetched) {
    return <Card loading />;
  }

  return (
    <Card
      title="Define application scaling settings"
      bodyStyle={{ padding: '10px' }}
      headStyle={{ padding: '10px' }}
      className="rounded-sm script-card"
    >
      <div>
        <Spin spinning={scale$.isLoading || false}>
          <Form onFinish={onFormSubmit} ref={form} layout="vertical">
            <div className="mb-3">
              <Checkbox checked={autoscaling} onChange={(e) => setAutoscaling(e.target.checked)}>
                Auto-scaling
              </Checkbox>
            </div>
            {autoscaling ? (
              <AutoScalingForm
                form={form.current}
                values={[
                  values$.data?.data?.autoscaling?.minReplicas,
                  values$.data?.data?.autoscaling?.maxReplicas,
                ]}
              />
            ) : (
              <StaticScalingForm form={form.current} />
            )}
            <fieldset>
              <div className="flex flex-row items-center text-gray-500 mb-2">
                <InfoCircleOutlined className="cursor-pointer mr-2 text-blue-500" />
                <span className="text-lg flex-grow">Request resources</span>
                <Select
                  showSearch
                  value={ram}
                  onChange={(value) => setRam(value)}
                  placeholder="Memory units"
                  className="w-28"
                >
                  <Select.OptGroup key="SI">
                    <Select.Option value="E">E</Select.Option>
                    <Select.Option value="P">P</Select.Option>
                    <Select.Option value="T">T</Select.Option>
                    <Select.Option value="G">G</Select.Option>
                    <Select.Option value="M">M</Select.Option>
                    <Select.Option value="k">k</Select.Option>
                  </Select.OptGroup>
                  <Select.OptGroup key="IEC">
                    <Select.Option value="Ei">Ei</Select.Option>
                    <Select.Option value="Pi">Pi</Select.Option>
                    <Select.Option value="Ti">Ti</Select.Option>
                    <Select.Option value="Gi">Gi</Select.Option>
                    <Select.Option value="Mi">Mi</Select.Option>
                    <Select.Option value="Ki">Ki</Select.Option>
                  </Select.OptGroup>
                </Select>
                <span className="mx-1" />
                <Select
                  value={cpu}
                  onChange={(value) => setCpu(value)}
                  placeholder="CPU unit"
                  className="w-28"
                >
                  <Select.Option value="m">millicpu (m)</Select.Option>
                  <Select.Option value={null}>cpu</Select.Option>
                </Select>
              </div>
              <Row>
                <Col span={12}>
                  <Form.Item initialValue={1} name="resources.requests.memory" label="Memory">
                    <InputNumber
                      min={1}
                      step={1}
                      className="w-full"
                      disabled={!ram && !isNull(ram)}
                      formatter={(value) => (ram ? `${value}${ram}` : value)}
                      parser={(value) => (ram ? value.replace(ram, '') : value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item initialValue={1} name="resources.requests.cpu" label="CPU">
                    <InputNumber
                      min={1}
                      step={1}
                      className="w-full"
                      disabled={!cpu && !isNull(cpu)}
                      formatter={(value) => (cpu ? `${value}${cpu}` : value)}
                      parser={(value) => (cpu ? value.replace(cpu, '') : value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </fieldset>
            <fieldset>
              <div className="flex flex-row items-center text-gray-500 mb-2">
                <InfoCircleOutlined className="cursor-pointer mr-2 text-blue-500" />
                <span className="text-lg">Resource limits</span>
              </div>
              <Row>
                <Col span={12}>
                  <Form.Item initialValue={1} name="resources.limits.memory" label="Memory">
                    <InputNumber
                      min={1}
                      step={1}
                      className="w-full"
                      disabled={!ram && !isNull(ram)}
                      formatter={(value) => (ram ? `${value}${ram}` : value)}
                      parser={(value) => (ram ? value.replace(ram, '') : value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item initialValue={1} name="resources.limits.cpu" label="CPU">
                    <InputNumber
                      min={1}
                      step={1}
                      className="w-full"
                      disabled={!cpu && !isNull(cpu)}
                      formatter={(value) => (cpu ? `${value}${cpu}` : value)}
                      parser={(value) => (cpu ? value.replace(cpu, '') : value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </fieldset>
            <Form.Item className="text-right">
              <Button htmlType="submit" type="primary">
                Apply changes
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Card>
  );
}

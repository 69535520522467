export class OrganizationsService {
  constructor(api) {
    this.api = api;
  }

  dbConnection(payload) {
    return this.api.post('organization/db-connection', payload).then(({ data }) => data);
  }

  getOrganizations(filters = {}) {
    return this.api.get('organization', { params: filters }).then(({ data }) => data);
  }

  getOrganizationInfo(id) {
    return this.api.get(`organization/${id}`).then(({ data }) => data);
  }

  addOrganization(payload) {
    return this.api.post('organization', payload).then(({ data }) => data);
  }

  updateOrganizationInfo({ id, ...data }) {
    return this.api.put(`organization/${id}/info`, data).then(({ data }) => data);
  }

  patchOrganizationDeployment(payload) {
    return this.api
      .patch(`/organization/${payload.organization}/deployment`, payload)
      .then(({ data }) => data);
  }

  patchOrganizationEnv(payload) {
    return this.api
      .patch(`/organization/${payload.organization}/envs`, payload)
      .then(({ data }) => data);
  }

  getReleaseStatus(organization) {
    return this.api.get(`/organization/${organization}/release`).then(({ data }) => data);
  }

  getHealthStatus(organization) {
    return this.api.get(`/organization/${organization}/health`).then(({ data }) => data);
  }

  deleteOrganization(payload) {
    return this.api.delete(`/organization`, { data: payload }).then(({ data }) => data);
  }

  getOrganizationBackups(id) {
    return this.api.get(`/organization/${id}/backup/list`).then(({ data }) => data);
  }

  createBackup(organization) {
    return this.api
      .post(`/organization/${organization}/backup/create`, {})
      .then(({ data }) => data);
  }

  restoreBackup({ organization, backup }) {
    return this.api
      .post(`/organization/${organization}/backup/restore/${backup}`, {})
      .then(({ data }) => data);
  }

  releaseValues(organization) {
    return this.api.get(`/organization/${organization}/release/values`).then(({ data }) => data);
  }

  pods(organization) {
    return this.api.get(`/organization/${organization}/release/pods`).then(({ data }) => data);
  }

  restartPod({ organization, pod }) {
    return this.api
      .delete(`/organization/${organization}/pods/${pod}/restart`)
      .then(({ data }) => data);
  }

  scale({ organization, ...values }) {
    return this.api.patch(`/organization/${organization}/scale`, values).then(({ data }) => data);
  }

  getOrganizationUsers(organizationId) {
    return this.api
      .get(`/organization/${organizationId}/organization-users`)
      .then(({ data }) => data);
  }

  getActiveUsers(organizationId) {
    return this.api.get(`/organization/${organizationId}/active-users`).then(({ data }) => data);
  }

  getLoggedUsers(organizationId) {
    return this.api.get(`/organization/${organizationId}/logged-users`).then(({ data }) => data);
  }
}

import { Button, Col, Row } from 'antd';
import { useMemo, useRef } from 'react';

import { APP_SCOPES } from '../../../constants/services';
import { DomainsList } from '../containers/DomainsList';
import { IfUserCan } from '../../../context/permissions';
import { PageHeader } from '@ant-design/pro-layout';
import { Repositories } from '../containers/Repositories';
import { SysInfo } from '../containers/SysInfo';
import { SystemActivitiesDrawer } from '../containers/SystemActivitiesDrawer';
import { SystemLogs } from '../containers/SystemLogs';
import { useNavigate } from 'react-router-dom';
import { withPermissions } from '../../../context/permissions';

export const SystemDashboard = withPermissions([APP_SCOPES.ENV], function SystemDashboard() {
  const domain = useRef();
  const activities$ = useRef();
  const navigate = useNavigate();

  const onBack = () => navigate.goBack();
  const extra = useMemo(
    () => (
      <div className="flex flex-row items-center">
        <Button.Group>
          <IfUserCan scopes={[APP_SCOPES.DOMAINS]}>
            <Button onClick={() => activities$.current?.toggle()}>Activities</Button>
            <Button onClick={() => domain.current?.toggle()}>Manage domains</Button>
          </IfUserCan>
        </Button.Group>
      </div>
    ),
    [],
  );
  return (
    <div className="bg-white min-h-full shadow-md overflow-y-auto flex flex-col">
      <PageHeader
        onBack={onBack}
        extra={extra}
        title="System preferences"
        className="border-b-1 mb-5"
      />
      <div className="px-5 pb-5 flex flex-col flex-grow">
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <SysInfo />
          </Col>
          <Col span={12}>
            <Repositories />
          </Col>
        </Row>
        <SystemLogs />
      </div>

      <DomainsList.Drawer ref={domain} />
      <SystemActivitiesDrawer ref={activities$} />
    </div>
  );
});

import {
  AppstoreOutlined,
  DeploymentUnitOutlined,
  LogoutOutlined,
  MenuOutlined,
  RocketOutlined,
  SettingOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { APP_SCOPES } from '../constants/services';
import { ConnectionStatus } from '../modules/system/components/ConnectionStatus';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsCan } from '../context/permissions';
import { useState } from 'react';

export const ApplicationHeader = () => {
  const can = useIsCan();
  const { user, logout } = useAuth0();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname || '/organizations');
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === '/sign-out') {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } else if (e.key) {
      setCurrent(e.key);
      navigate(e.key);
    }
  };
  return (
    <Layout
      className="h-screen"
      style={{
        height: '70px',
      }}
    >
      <Layout.Header
        className="flex flex-row items-center w-full"
        style={{ paddingLeft: '20px', paddingRight: '10px' }}
      >
        <img src="/images/csi-logo-white.svg" alt="Logo" />
        <ConnectionStatus />
        <div className="w-full flex flex-row items-center">
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={current}
            overflowedIndicator={<MenuOutlined />}
            style={{
              marginLeft: 'auto',
            }}
            items={[
              {
                index: 'true',
                element: '/',
              },
              {
                label: 'Organizations',
                key: '/organizations',
                icon: <AppstoreOutlined />,
                disabled: !can([APP_SCOPES.ORGANIZATIONS]),
              },
              {
                label: 'Environment',
                key: '/system/env',
                icon: <RocketOutlined />,
                disabled: !can([APP_SCOPES.ENV]),
              },

              {
                label: 'Vendors',
                key: '/vendors',
                icon: <SwapOutlined />,
                disabled: !can([APP_SCOPES.VENDORS]),
              },
              {
                label: 'System',
                key: '/system',
                icon: <SettingOutlined />,
                disabled: !can([APP_SCOPES.SYSTEM]),
              },
              {
                label: 'Deployments',
                key: '/deployments',
                icon: <DeploymentUnitOutlined />,
                disabled: !can([APP_SCOPES.SYSTEM]),
              },
              {
                label: user.name,
                picture: user.picture,
                key: '/username',
                icon: <UserOutlined />,
                children: [
                  {
                    label: 'Sign out',
                    icon: <LogoutOutlined />,
                    key: '/sign-out',
                  },
                ],
              },
            ]}
            onClick={handleMenuClick}
          />
        </div>
      </Layout.Header>
    </Layout>
  );
};

import { createContext, useContext } from 'react';

import { createServices } from '../services';

const ServiceContext = createContext();

export function useProvideServices(deps) {
  return createServices(deps);
}

export function useServices() {
  return useContext(ServiceContext);
}

export function useService(key) {
  const services = useServices();
  return services[key];
}

export function ServicesProvider({ axios, children }) {
  const services = useProvideServices({ axios });
  return <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>;
}

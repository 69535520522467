import {
  BankOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Select, Table, Tooltip, Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { capitalize, isEmpty } from 'lodash';

import { APP_SCOPES } from '../../../constants/services';
import { PageHeader } from '@ant-design/pro-layout';
import {
  useGetOrganizationHealthStatus,
  useGetOrganizations,
} from '../../../hooks/organizations/organizations-management';
import { useState } from 'react';
import { withPermissions } from '../../../context/permissions';
import * as moment from 'moment';

function OrganizationStatus({ organization }) {
  const { health, checkedAt, status } = organization;

  if (status === 'deploying') {
    return (
      <Tooltip overlay={capitalize(organization.status)}>
        <ClockCircleOutlined className="text-blue-600" />
      </Tooltip>
    );
  }

  if (!isEmpty(health) && !!checkedAt && status === 'deployed') {
    return (
      <Tooltip
        overlay={<span className="text-xs">Last check: {moment(checkedAt).format('L LT')}</span>}
      >
        {health.status === 'ok' ? (
          <CheckCircleOutlined className="text-green-600" />
        ) : (
          <CloseCircleOutlined className="text-red-600" />
        )}
      </Tooltip>
    );
  }

  return <span className="capitalize text-xs">{organization.status}</span>;
}

function VersionText({ organization }) {
  const { data } = useGetOrganizationHealthStatus(organization.id);
  const strippedAzureVersion = data?.info?.application?.appVersion?.split('csi-lawyer-app:')[1];

  if (strippedAzureVersion && organization?.appVersion) {
    if (strippedAzureVersion !== organization.appVersion) {
      return (
        <div className="flex flex-row">
          <p className="pr-2">{organization.appVersion}</p>
          <Tooltip title="Deployed API version does not match with the version configuration in Azure">
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row">
          <p className="pr-2">{organization.appVersion}</p>
          <CheckCircleOutlined className="text-green-600" />
        </div>
      );
    }
  }

  return organization?.version;
}

export const Organizations = withPermissions([APP_SCOPES.ORGANIZATIONS], function Organizations() {
  const navigate = useNavigate();
  const [schema, setSchema] = useState('lawyer');
  const organizations = useGetOrganizations({ schema });

  const onSelectOrganization = (organization) => navigate(`/organizations/${organization.id}`);

  return (
    <div className="bg-white min-h-full shadow-md overflow-y-auto">
      <PageHeader
        title="All organizations"
        extra={
          <>
            <Select
              className="mr-1 w-36"
              placeholder="Schema"
              value={schema}
              onChange={(schema) => setSchema(schema)}
            >
              <Select.Option value="lawyer">CSI Lawyer</Select.Option>
              <Select.Option value="trustee">CSI Trustee</Select.Option>
            </Select>
            <Button.Group>
              <Link to={`/organizations/new/${schema}`}>
                <Button type="default" className="flex flex-row items-center">
                  <BankOutlined />
                  New organization
                </Button>
              </Link>
              <Button onClick={organizations.refetch} className="flex flex-row items-center">
                <ReloadOutlined />
                Refresh
              </Button>
            </Button.Group>
          </>
        }
      />
      <Layout.Content style={{ padding: '0 15px' }}>
        <Table
          bordered
          pagination={false}
          rowClassName="cursor-pointer"
          loading={organizations.isLoading || organizations.isFetching}
          onRow={(row) => ({
            onClick: () => onSelectOrganization(row),
          })}
          dataSource={organizations.data}
          columns={[
            { title: 'Name', dataIndex: 'name' },
            { title: 'Owner', dataIndex: 'ownerName' },
            {
              title: 'Subdomain',
              dataIndex: 'subdomain',
              render: (subdomain) => (
                <a target="_blank" href={`//${subdomain}`} rel="noreferrer">
                  {subdomain}
                </a>
              ),
            },
            {
              title: 'Version',
              dataIndex: 'appVersion',
              render: (version, organization) => <VersionText organization={organization} />,
            },
            {
              title: 'Live',
              className: 'w-10 text-center',
              render: (organization) => <OrganizationStatus organization={organization} />,
            },
          ]}
        />
      </Layout.Content>
    </div>
  );
});

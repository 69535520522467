import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, List, message, Modal } from 'antd';
import moment from 'moment';
import { CreateNewKeyForm } from './CreateNewKeyForm';
import classNames from 'classnames';
import { APP_SCOPES } from '../../../constants/services';
import { useIsCan } from '../../../context/permissions';

export const KeysList = function KeysList({
  keys,
  createNewKeyModal,
  setCreateNewKeyModal,
  onRemoveKey,
  addNewKey,
  createNewKeyModalRef,
  title,
}) {
  const can = useIsCan();
  return (
    <>
      <List
        pagination={{ pageSize: 5 }}
        style={{ padding: 0 }}
        loading={keys.isLoading}
        bordered
        dataSource={keys.data ? keys.data : []}
        header={
          <div className="flex flex-row">
            <h2 className="text-lg flex-grow">{title}</h2>
            <Button.Group>
              <Button
                icon={<PlusOutlined />}
                onClick={() => setCreateNewKeyModal(true)}
                className="ant-select-selection-item-remove"
                type="primary"
              >
                Create new key
              </Button>
            </Button.Group>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                disabled={keys.data.length === 1 || !can([APP_SCOPES['DELETE_VENDORS']])}
                key={'remove-key'}
                onClick={() => onRemoveKey(item.id)}
                danger
              >
                Remove
              </Button>,
            ]}
            key={item.id}
            className="px-3 cursor-pointer hover:bg-gray-50"
          >
            <List.Item.Meta
              key={item.id}
              description={
                <>
                  <p className="text-xs">Created: {moment(item.createdAt).format('DD.MM.YYYY')}</p>
                  <p
                    className={classNames(
                      'text-xs',
                      moment(item.validity).isBefore(moment(), 'D') ? 'text-red-500 font-bold' : '',
                    )}
                  >
                    {`${moment(item.validity).isBefore(moment(), 'D') ? 'EXPIRED - ' : ''} Valid until: 
                    ${item.validity ? moment(item.validity).format('DD.MM.YYYY') : 'No expiration'}`}
                  </p>
                  <Input.Password className="w-2/4 text-xs font-normal" value={item.key} />
                  <Button
                    className="ml-5"
                    onClick={() => {
                      navigator.clipboard.writeText(item.key);
                      message.success('Key copied to clipboard');
                    }}
                  >
                    <CopyOutlined />
                  </Button>
                </>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        width={350}
        onCancel={() => setCreateNewKeyModal(false)}
        onOk={addNewKey}
        open={createNewKeyModal}
      >
        <CreateNewKeyForm ref={createNewKeyModalRef} />
      </Modal>
    </>
  );
};

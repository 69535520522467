import { message, Modal } from 'antd';
import {
  useAddKey,
  useGetKeysForApplication,
  useRemoveVendorKey,
} from '../../../hooks/vendors/vendors-management';
import moment from 'moment';

import { useRef, useState } from 'react';
import useInvalidateVendorActivityQueries from '../hooks/useInvalidateVendorActivityQueries';
import { KeysList } from '../components/KeysList';

export const ApplicationKeysContainer = function KeysContainer({ application = {} }) {
  const [createNewKeyModal, setCreateNewKeyModal] = useState(false);
  const createNewKeyModalRef = useRef();

  const keys = useGetKeysForApplication(application?.id, { enabled: !!application?.id });
  const invalidateVendorActivityQueries = useInvalidateVendorActivityQueries();

  const removeKey = useRemoveVendorKey({
    onSuccess: () => {
      keys.refetch();
      message.success('Key removed!');
      invalidateVendorActivityQueries();
    },
  });

  const addKey = useAddKey({
    onSuccess: () => {
      keys.refetch();
      message.success('Key created');
      setCreateNewKeyModal(false);
      invalidateVendorActivityQueries();
    },
  });

  const addNewKey = () => {
    createNewKeyModalRef.current?.validateFields().then((values) =>
      addKey.mutateAsync({
        applicationId: application.id,
        validity: values?.validity ? moment(new Date(values.validity)).format() : null,
      }),
    );
  };

  const onRemoveKey = (keyId) => {
    Modal.confirm({
      title: `Are you sure you want to delete this application key?`,
      okButtonProps: { danger: true },
      onOk: () => removeKey.mutateAsync({ keyId }),
      content: `The key will be permanently deleted.`,
    });
  };

  return (
    <KeysList
      title={'Application keys'}
      keys={keys}
      createNewKeyModal={createNewKeyModal}
      setCreateNewKeyModal={setCreateNewKeyModal}
      onRemoveKey={onRemoveKey}
      addNewKey={addNewKey}
      createNewKeyModalRef={createNewKeyModalRef}
    />
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Spin } from 'antd';
import { ServicesProvider } from './services';

const TOKEN_INITIALIZATION = 'TOKEN_INITIALIZATION';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
export function useProvideAuth() {
  const [token, setToken] = useState(TOKEN_INITIALIZATION);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE || 'read:current_user';

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          scope: auth0Scope,
        },
      }).then(setToken);
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, auth0Scope]);
  return {
    user,
    token,
    isLoading: !token || isLoading || token === TOKEN_INITIALIZATION,
  };
}

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  const api = useMemo(() => {
    if (!auth.isLoading && auth.token) {
      return axios.create({
        baseURL: (process.env.REACT_APP_API_URL || '') + '/api',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
    }

    return axios.create({ baseURL: (process.env.REACT_APP_API_URL || '') + '/api' });
  }, [auth.isLoading, auth.token]);

  if (auth.isLoading) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <Spin />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={auth}>
      <ServicesProvider axios={api}>{children}</ServicesProvider>
    </AuthContext.Provider>
  );
}

import { Col, Form, Input, Row, Select } from 'antd';
import { getCountriesListOptions } from '../../../utils/countries';
import { languages } from '../../../../../constants/languages';
import { DomainSelect } from '../DomainSelect';
import { kebabCase } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';

export function NewOrganizationGeneralInfo({
  organizationData = {},
  setOrganizationData,
  setNextStepEnabled,
}) {
  const [orgGeneralInfo, setOrgGeneralInfo] = useState(organizationData.general);
  const [form] = Form.useForm();

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setNextStepEnabled(true);
      },
      () => {
        setNextStepEnabled(false);
      },
    );
  }, [form, setNextStepEnabled, orgGeneralInfo]);

  const onOrganizationDataChange = (data) => {
    if (data.name) {
      form.setFieldsValue({ subdomain: kebabCase(data.name) });
      data.subdomain = kebabCase(data.name);
    }

    organizationData.general = { ...orgGeneralInfo, ...data };
    setOrganizationData(organizationData);
    console.debug('onOrganizationDataChange', organizationData);
    setOrgGeneralInfo(organizationData.general);
  };

  const setDomain = (domain) => {
    onOrganizationDataChange({ domain: domain });
  };

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      onValuesChange={onOrganizationDataChange}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        name="name"
        label="Organization name"
        initialValue={orgGeneralInfo.name}
        className="mb-2"
        rules={[{ required: true, min: 2 }]}
      >
        <Input placeholder="e.g. CSI Helsinki" />
      </Form.Item>
      <Form.Item
        name="subdomain"
        label="Domain"
        initialValue={orgGeneralInfo.subdomain}
        dependencies={['name']}
        rules={[
          { required: true },
          { min: 5, max: 32 },
          {
            validator: (rule, value, cb) => (!orgGeneralInfo.domain ? cb('Select domain') : cb()),
          },
        ]}
      >
        <Input
          className="domain-input"
          placeholder="my-organization"
          addonAfter={
            <DomainSelect
              value={orgGeneralInfo.domain}
              onChange={setDomain}
              placeholder="example.com"
            />
          }
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="organizationLanguage"
            label="Language"
            initialValue={orgGeneralInfo.organizationLanguage}
            rules={[{ required: true }]}
          >
            <Select showSearch options={languages} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="homeCountry"
            label="Country"
            initialValue={orgGeneralInfo.homeCountry}
            rules={[{ required: true }]}
          >
            <Select showSearch options={getCountriesListOptions()} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="ownerName"
            label="Owner"
            initialValue={orgGeneralInfo.ownerName}
            className="mb-2"
            rules={[{ required: true }, { max: 100, min: 2 }]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="ownerEmail"
            label="Contact email"
            initialValue={orgGeneralInfo.ownerEmail}
            className="mb-2"
            rules={[
              { required: true, type: 'email' },
              { max: 100, min: 2 },
            ]}
          >
            <Input placeholder="john.doe@example.com" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="description"
        label="Description"
        initialValue={orgGeneralInfo.description}
        rules={[{ required: true }]}
      >
        <Input.TextArea placeholder="Few words about this organization..." />
      </Form.Item>
    </Form>
  );
}

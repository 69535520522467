import { Card, Select, message } from 'antd';
import {
  useGetDeployedReleasePods,
  useGetDeployedReleaseServices,
} from '../../../hooks/deployments/deployments';
import { useMemo, useState } from 'react';

import Code from 'react-highlight';
import { CopyOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { template } from 'lodash';

const templates = {
  pods: template(`kubectl get pods -n <%= namespace %>`),
  logs: template(`kubectl logs -n <%= namespace %> -f <%= pod %>`),
  connect: template(
    `kubectl exec --stdin --tty pod/<%= pod %> --namespace <%= namespace %> -- /bin/bash`,
  ),
  service: template(
    `kubectl port-forward --namespace <%= namespace %> svc/<%= service %> <%= port %>:<%= port %>`,
  ),
};

function Snippet({ template, release = {}, title, onCopy }) {
  const str = useMemo(() => template(release), [template, release]);

  return (
    <Card
      title={title}
      bodyStyle={{ padding: '10px' }}
      headStyle={{ padding: '10px' }}
      className="script-card"
    >
      <div className="flex flex-row items-center justify-between">
        <Code language="bash" className="mb-2">
          {str}
        </Code>
        <CopyOutlined onClick={() => onCopy(str)} className="text-blue-400 cursor-pointer" />
      </div>
    </Card>
  );
}

function WithPodSelection({ template, release, title, onCopy, pods = [] }) {
  const [pod, setPod] = useState();

  const str = useMemo(
    () => template({ ...release, pod: pod || '[select pod]' }),
    [pod, template, release],
  );

  const onCopyText = () => onCopy(str);

  const extra = (
    <>
      <span className="mr-2 font-semibold">Select pod: </span>
      <Select
        bordered={true}
        placeholder="Select pod..."
        clearIcon
        onSelect={setPod}
        value={pod}
        className="w-80"
      >
        {pods.map((pod) => (
          <Select.Option value={pod.name}>{pod.name}</Select.Option>
        ))}
      </Select>
    </>
  );

  return (
    <Card
      title={title}
      extra={extra}
      bodyStyle={{ padding: '10px' }}
      headStyle={{ padding: '10px' }}
      className="rounded-sm script-card"
    >
      <div className="flex flex-row items-center justify-between">
        <Code language="bash" className="mb-2">
          {str}
        </Code>
        <CopyOutlined
          onClick={pod ? onCopyText : null}
          className={cx('text-blue-400', {
            'opacity-60 cursor-not-allowed': !pod,
            'cursor-pointer': !!pod,
          })}
        />
      </div>
    </Card>
  );
}

function WithServiceSelection({ template, release, title, onCopy, services = [] }) {
  const [port, setPort] = useState();
  const [service, setService] = useState();

  const ports = useMemo(
    () => services.find(({ name }) => name === service)?.ports || [],
    [service],
  );
  const str = useMemo(
    () => template({ ...release, port: port || '0000', service: service || '[select service]' }),
    [port, service, template, release],
  );

  const onCopyText = () => onCopy(str);

  const extra = (
    <>
      <span>
        <span className="mr-2 font-semibold">Select service: </span>
        <Select
          bordered={true}
          placeholder="Select service..."
          clearIcon
          onSelect={setService}
          value={service}
          className="w-80"
        >
          {services.map((pod) => (
            <Select.Option value={pod.name}>{pod.name}</Select.Option>
          ))}
        </Select>
      </span>
      <span className="mx-2" />
      <span>
        <span className="mr-2 font-semibold">Select port: </span>
        <Select
          bordered={true}
          placeholder="Select service..."
          clearIcon
          onSelect={setPort}
          value={port}
          className="w-40"
        >
          {ports.map((pod) => (
            <Select.Option value={pod.port}>{pod.port}</Select.Option>
          ))}
        </Select>
      </span>
    </>
  );

  return (
    <Card
      title={title}
      extra={extra}
      bodyStyle={{ padding: '10px' }}
      headStyle={{ padding: '10px' }}
      className="rounded-sm script-card"
    >
      <div className="flex flex-row items-center justify-between">
        <Code language="bash" className="mb-2">
          {str}
        </Code>
        <CopyOutlined
          onClick={service ? onCopyText : null}
          className={cx('text-blue-400', {
            'opacity-60 cursor-not-allowed': !service,
            'cursor-pointer': !!service,
          })}
        />
      </div>
    </Card>
  );
}

export function Scripts({ release = {}, isLoading = false }) {
  const onCopy = (str) => {
    message.success('Copied!');
    navigator.clipboard.writeText(str);
  };

  const services$ = useGetDeployedReleaseServices(release.id);
  const pods$ = useGetDeployedReleasePods(release.id, { enabled: !!release.id });

  if (isLoading) {
    return <Card loading />;
  }

  return (
    <>
      <Snippet
        onCopy={onCopy}
        template={templates.pods}
        release={release}
        title="List application pods"
      />
      <br />
      <WithServiceSelection
        services={services$.data?.services || []}
        onCopy={onCopy}
        template={templates.service}
        release={release}
        title="Connect to service"
      />
      <br />
      <WithPodSelection
        pods={pods$.data?.pods || []}
        onCopy={onCopy}
        template={templates.logs}
        release={release}
        title="Pod logs"
      />
      <br />
      <WithPodSelection
        pods={pods$.data?.pods || []}
        onCopy={onCopy}
        template={templates.connect}
        release={release}
        title="Connect to container"
      />
    </>
  );
}

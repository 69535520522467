export const languages = [
  {
    label: 'Suomi',
    value: 'fi-FI',
  },
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: 'Svenska',
    value: 'sv-SE',
  },
];

export class EnvService {
  constructor(api) {
    this.api = api;
  }

  getSystemEnvs() {
    return this.api.get('/envs/system').then(({ data }) => data);
  }

  inspectEnv(id) {
    return this.api.get(`/envs/info/${id}`).then(({ data }) => data);
  }

  updateSystemEnv(payload) {
    return this.api.patch(`/envs/system`, payload).then(({ data }) => data);
  }

  createSystemEnv(payload) {
    return this.api.post('/envs/system', payload).then(({ data }) => data);
  }

  deleteSystemEnv(id) {
    return this.api.delete(`/envs/system/${id}`).then(({ data }) => data);
  }

  inspectChart(chart) {
    return this.api.get(`/envs/inspect-chart`, { params: { chart } }).then(({ data }) => data);
  }
}

import { useMutation, useQuery } from 'react-query';
import { SERVICE_KEYS } from '../../constants/services';
import { useService } from '../../context/services';

export function useGetRepositories(options = {}) {
  const k8s = useService(SERVICE_KEYS.K8S);
  return useQuery('k8s.repositories', () => k8s.getRepositories(), options);
}

export function useAddRepository(options = {}) {
  const k8s = useService(SERVICE_KEYS.K8S);
  return useMutation((payload) => k8s.addRepository(payload), options);
}

export function useSyncAllRepositories(options = {}) {
  const k8s = useService(SERVICE_KEYS.K8S);
  return useMutation((payload) => k8s.syncAllRepositories(payload), options);
}

import { useMutation, useQuery } from 'react-query';

import { SERVICE_KEYS } from '../../constants/services';
import { useService } from '../../context/services';

export function useGetDeployedReleases(filters = {}, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(['deployments.list', filters], () => service.deployments(filters), options);
}

export function useGetDeploymentFilters(options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(['deployments.filters'], () => service.filters(), options);
}

export function useGetDeployedRelease(id, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(['deployments.release', id], () => service.find(id), options);
}

export function useDeployRelease(options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useMutation((payload) => service.deployRelease(payload), options);
}

export function useGetInspectChart(chart, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(['deployments.inspect-chart', { chart }], () => service.chart(chart), options);
}

export function useGetInspectRelease(release, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(
    ['deployments.inspect-release', { release }],
    () => service.release(release),
    options,
  );
}

export function useGetDeployedReleasePods(release, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(['deployments.pods', { release }], () => service.pods(release), options);
}

export function useGetDeployedReleaseServices(release, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(['deployments.services', { release }], () => service.services(release), options);
}

export function useGetDeploymentReleaseStatus(release, options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useQuery(
    ['deployments.status', { release }],
    () => service.releaseStatus(release),
    options,
  );
}

export function useDeleteDeployedRelease(options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useMutation((release) => service.deleteRelease(release), options);
}

export function useUpdateDeployedRelease(options = {}) {
  const service = useService(SERVICE_KEYS.DEPLOYMENTS);
  return useMutation((payload) => service.update(payload), options);
}

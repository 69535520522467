import { Button, Modal, Select, message } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetReleasePods,
  useRestartPod,
} from '../../../../hooks/organizations/organizations-management';

import { FitAddon } from '@xterm/addon-fit';
import IO from 'socket.io-client';
import { XTerm } from 'xterm-for-react';

export function OrganizationPodLogs({ organization = {} }) {
  const term = useRef();
  const [pod, setPod] = useState();
  const fitAddon = useMemo(() => new FitAddon(), []);

  const podsFromApi = useGetReleasePods(organization.id, {
    enabled: !!organization.id,
  });

  const pods = useMemo(() => podsFromApi.data?.pods || [], [podsFromApi.data]);

  const restartPod = useRestartPod({
    onSuccess: () => {
      setPod(null);
      podsFromApi.refetch();
      message.success('Restarting current pod...');
    },
  });

  const onRestartPod = () =>
    Modal.confirm({
      title: "You're about pod restarting",
      okButtonProps: { type: 'primary', danger: true },
      onOk: () => restartPod.mutateAsync({ pod, organization: organization.id }),
      content: 'Please note, it may cause about 1m downtime until new instance will start.',
    });

  useEffect(() => {
    if (!term?.current?.terminal) {
      return;
    }

    if (pod) {
      term.current.terminal.clear();
      try {
        fit.fit();
      } catch (e) {
        console.debug(e);
      }
      const socket = new IO(process.env.REACT_APP_API_URL);
      socket.emit('organization.logs', { organization: organization.id, pod });
      socket.on('organization.logs', (chunk) => term.current.terminal.writeln(`${chunk.logs}\n`));

      return () => socket.disconnect();
    } else {
      term.current.terminal.writeln(`Select pod from list...\n`);
    }
  }, [pod, organization, term, fitAddon]);

  return (
    <>
      <div className="flex flex-row items-center mb-3">
        <Select
          placeholder="Application pods..."
          clearIcon
          onSelect={setPod}
          value={pod}
          className="flex-grow mr-3"
        >
          {pods.map((pod) => (
            <Select.Option value={pod.name}>{pod.name}</Select.Option>
          ))}
        </Select>
        <Button type="primary" disabled={!pod} onClick={onRestartPod} danger>
          Restart pod
        </Button>
      </div>
      <XTerm
        ref={term}
        addons={[fitAddon]}
        className="flex-grow"
        options={{
          windowOptions: { maximizeWin: true },
          cols: 200,
          rows: 60,
          convertEol: true,
          rendererType: 'canvas',
          allowProposedApi: true,
        }}
      />
    </>
  );
}

import { Card, Select, message } from 'antd';
import { useMemo, useState } from 'react';

import Code from 'react-highlight';
import { CopyOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { template } from 'lodash';
import { useGetReleasePods } from '../../../../hooks/organizations/organizations-management';

const templates = {
  pods: template(`kubectl get pods -n <%= namespace %>`),
  logs: template(`kubectl logs -n <%= namespace %> -f <%= pod %>`),
  connect: template(
    `kubectl exec --stdin --tty pod/<%= pod %> --namespace <%= namespace %> -- /bin/bash`,
  ),
  db: template(
    `kubectl port-forward --namespace <%= namespace %> svc/<%= releaseName %>-postgresql 5432:5432`,
  ),
};

function Snippet({ template, organization = {}, title, onCopy }) {
  const str = useMemo(() => template(organization), [template, organization]);

  return (
    <Card
      title={title}
      bodyStyle={{ padding: '10px' }}
      headStyle={{ padding: '10px' }}
      className="script-card"
    >
      <div className="flex flex-row items-center justify-between">
        <Code language="bash" className="mb-2">
          {str}
        </Code>
        <CopyOutlined onClick={() => onCopy(str)} className="text-blue-400 cursor-pointer" />
      </div>
    </Card>
  );
}

function WithPodSelection({ template, organization, title, onCopy }) {
  const [pod, setPod] = useState();
  const pods$ = useGetReleasePods(organization.id, { enabled: !!organization.id });
  const pods = useMemo(() => pods$.data?.pods || [], [pods$.data]);

  const str = useMemo(
    () => template({ ...organization, pod: pod || '[select pod]' }),
    [pod, template, organization],
  );

  const onCopyText = () => onCopy(str);

  const extra = (
    <>
      <span className="mr-2 font-semibold">Select pod: </span>
      <Select
        bordered={true}
        placeholder="Select pod..."
        clearIcon
        onSelect={setPod}
        value={pod}
        className="w-80"
      >
        {pods.map((pod) => (
          <Select.Option value={pod.name}>{pod.name}</Select.Option>
        ))}
      </Select>
    </>
  );

  return (
    <Card
      title={title}
      extra={extra}
      bodyStyle={{ padding: '10px' }}
      headStyle={{ padding: '10px' }}
      className="rounded-sm script-card"
    >
      <div className="flex flex-row items-center justify-between">
        <Code language="bash" className="mb-2">
          {str}
        </Code>
        <CopyOutlined
          onClick={pod ? onCopyText : null}
          className={cx('text-blue-400', {
            'opacity-60 cursor-not-allowed': !pod,
            'cursor-pointer': !!pod,
          })}
        />
      </div>
    </Card>
  );
}

export function OrganizationScripts({ organization = {} }) {
  const onCopy = (str) => {
    message.success('Copied!');
    navigator.clipboard.writeText(str);
  };

  return (
    <>
      <Snippet
        onCopy={onCopy}
        template={templates.pods}
        organization={organization}
        title="List application pods"
      />
      <br />
      <Snippet
        onCopy={onCopy}
        template={templates.db}
        organization={organization}
        title="Connect to DB"
      />
      <br />
      <WithPodSelection
        onCopy={onCopy}
        template={templates.logs}
        organization={organization}
        title="Pod logs"
      />
      <br />
      <WithPodSelection
        onCopy={onCopy}
        template={templates.connect}
        organization={organization}
        title="Connect to container"
      />
    </>
  );
}

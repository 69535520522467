export const SERVICE_KEYS = {
  K8S: 'K8S',
  ENVS: 'ENVS',
  SYSTEM: 'SYSTEM',
  VENDORS: 'VENDORS',
  ACTIVITY: 'ACTIVITY',
  DEPLOYMENTS: 'DEPLOYMENTS',
  ORGANIZATIONS: 'ORGANIZATIONS',
};

export const APP_SCOPES = {
  ENV: 'manage:env',
  SYSTEM: 'manage:system',
  VENDORS: 'manage:vendors',
  DELETE_VENDORS: 'manage:delete-vendors',
  DELETE_ORGANIZATIONS: 'manage:delete-organizations',
  DOMAINS: 'manage:domains',
  RELEASES: 'manage:releases',
  ORGANIZATIONS: 'manage:organizations',
  SENSITIVEDATA: 'manage:sensitive-data',
};

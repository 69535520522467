import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Form, Input, Radio, Row, Switch, notification } from 'antd';
import { useEffect, useMemo } from 'react';

import { useDbConnectionTest } from '../../../../../hooks/organizations/organizations-management';
import { useState } from 'react';

export function NewOrganizationDatabaseInfo({
  organizationData = {},
  setOrganizationData,
  setNextStepEnabled,
}) {
  const [database, setDatabase] = useState(organizationData.database);
  const [form] = Form.useForm();

  useEffect(() => {
    setNextStepEnabled(true);
    // form.validateFields({ validateOnly: true }).then(
    //   () => {
    //     setNextStepEnabled(true);
    //   },
    //   () => {
    //     setNextStepEnabled(false);
    //   },
    // );
  }, [form, setNextStepEnabled]);

  const onDatabaseDataChange = (data) => {
    organizationData.database = { ...database, ...data };
    setOrganizationData(organizationData);
    setDatabase(organizationData.database);
  };

  const databaseConnectionTest = useDbConnectionTest({
    onSuccess: () => {
      notification.success({
        message: 'Connected',
        description: 'Credentials are valid and DB server is alive',
      });
    },
    onError: (e) => {
      notification.error({
        message: 'Connection error',
        description: 'Please check provided connection data',
      });
    },
  });

  const onDatabaseConnectionCheck = () =>
    databaseConnectionTest.mutateAsync(form.current?.getFieldsValue());

  const databaseConnectionAddon = useMemo(() => {
    let icon;
    if (databaseConnectionTest.isLoading) {
      icon = <LoadingOutlined className="text-blue-500" />;
    } else if (databaseConnectionTest.isError) {
      icon = <CloseCircleOutlined className="text-red-500" />;
    } else if (databaseConnectionTest.isSuccess) {
      icon = <CheckCircleOutlined className="text-green-500" />;
    }

    return (
      <button
        onClick={onDatabaseConnectionCheck}
        type="button"
        className="inline-flex flex-row items-center"
      >
        <span className="mr-2">Test connection</span>
        {icon}
      </button>
    );
  }, [
    databaseConnectionTest.isLoading,
    databaseConnectionTest.isError,
    databaseConnectionTest.isSuccess,
  ]);

  return (
    <Form ref={form} onValuesChange={onDatabaseDataChange} layout="vertical" autoComplete="off">
      <Row gutter={16}>
        <Col span={2}>
          <Form.Item
            name="useOwnDb"
            initialValue={database.useOwnDb}
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col style={{ textAlign: 'left', padding: '10px' }}>Use own database</Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="dbMode"
            label="Database Mode"
            initialValue={database.dbMode || 'new'}
            rules={[{ required: database.useOwnDb }]}
            className="mb-2"
          >
            <Radio.Group disabled={!database.useOwnDb} className="w-full flex flex-row">
              <Radio.Button className="flex-1 text-center" value="new">
                New
              </Radio.Button>
              <Radio.Button className="flex-1 text-center" value="existing">
                Existing
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dbType"
            label="Database type"
            initialValue={database.dbType}
            rules={[{ required: database.useOwnDb }]}
            className="mb-2"
          >
            <Radio.Group disabled={!database.useOwnDb} className="w-full flex flex-row">
              <Radio.Button className="flex-1 text-center" value="postgres">
                Postgresql
              </Radio.Button>
              <Radio.Button className="flex-1 text-center" value="mssql">
                MsSQL
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="dbHost"
        label="Connection options"
        extra="Please set only hostname with port and protocol."
        initialValue={database.dbHost}
        rules={[{ required: database.useOwnDb }]}
        className="mb-2"
      >
        <Input
          disabled={!database.useOwnDb}
          placeholder="postgresql://localhost:5432"
          addonAfter={databaseConnectionAddon}
        />
      </Form.Item>
      <Form.Item
        name="dbDatabase"
        initialValue={database.dbDatabase}
        rules={[{ required: database.useOwnDb }]}
        className="mb-2"
      >
        <Input placeholder="Database name" disabled={!database.useOwnDb} />
      </Form.Item>
      <Row gutter={16} className="mb-2">
        <Col span={12}>
          <Form.Item
            name="dbUsername"
            initialValue={database.dbUsername}
            rules={[{ required: database.useOwnDb }]}
          >
            <Input placeholder="Username" disabled={!database.useOwnDb} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dbPassword"
            initialValue={database.dbPassword}
            rules={[{ required: database.useOwnDb }]}
            colon={false}
          >
            <Input.Password placeholder="Password" disabled={!database.useOwnDb} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

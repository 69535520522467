import { Button, Descriptions, Skeleton, Tag } from 'antd';
import {
  CheckOutlined,
  CloseCircleOutlined,
  HighlightOutlined,
  MailOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import { VendorKeysContainer } from '../containers/VendorKeysContainer';
import { VendorApplicationsContainer } from '../containers/VendorApplicationsContainer';
import { useIsCan } from '../../../context/permissions';
import { APP_SCOPES } from '../../../constants/services';

export const VendorSummary = function VendorSummary({
  isLoading,
  isFetched,
  vendor = {},
  editVendor,
  changeVendorStatus,
}) {
  const can = useIsCan();
  return (
    <>
      {isLoading && <Skeleton />}
      {isFetched && (
        <Descriptions
          title="General information"
          layout="vertical"
          bordered
          extra={[
            <Button
              disabled={!can([APP_SCOPES['DELETE_VENDORS']])}
              onClick={changeVendorStatus}
              danger={vendor.status === 'active'}
              key={'status'}
              type="primary"
              className={'inline-flex items-center mr-2 '}
            >
              {vendor.status === 'active' ? <CloseCircleOutlined /> : <CheckOutlined />}
              {vendor.status === 'active' ? 'Deactivate' : 'Activate'}
            </Button>,
            <Button key={'edit'} className="inline-flex items-center" onClick={editVendor}>
              <HighlightOutlined />
              Edit
            </Button>,
          ]}
        >
          <Descriptions.Item label="Name">{vendor.name}</Descriptions.Item>
          <Descriptions.Item label="Identifier">{vendor.identifier}</Descriptions.Item>

          <Descriptions.Item label="Created at">
            {moment(vendor.createdAt).format('L LT')}
          </Descriptions.Item>
          <Descriptions.Item label="Contact email">
            <a
              className="inline-flex items-center text-blue-500"
              referrerPolicy="no-referrer"
              target="_blank"
              href={vendor.contactEmail && `mailto:${vendor.contactEmail}`}
              rel="noreferrer"
            >
              <MailOutlined className="mr-2" />
              {vendor.contactEmail ?? '-'}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Vendor tags">
            {vendor?.tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item>{''}</Descriptions.Item>

          <Descriptions.Item label="Description">{vendor.description}</Descriptions.Item>
        </Descriptions>
      )}
      <br />
      {isLoading && <Skeleton />}

      <div className="pb-5">
        <VendorKeysContainer vendor={vendor} />
      </div>

      <div className="pb-5">
        <VendorApplicationsContainer vendor={vendor} />
      </div>

      <br />
    </>
  );
};

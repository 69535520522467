import { SERVICE_KEYS } from '../../constants/services';
import { useQuery } from 'react-query';
import { useService } from '../../context/services';

export function useGetOrganizationActivities(id, options = {}) {
  const service = useService(SERVICE_KEYS.ACTIVITY);
  return useQuery(
    ['activity.organization', id],
    () => service.getOrganizationActivities(id),
    options,
  );
}

export function useGetVendorActivities(id, options = {}) {
  const service = useService(SERVICE_KEYS.ACTIVITY);
  return useQuery(['activity.vendor', id], () => service.getVendorActivities(id), options);
}

export function useGetReleaseActivities(id, options = {}) {
  const service = useService(SERVICE_KEYS.ACTIVITY);
  return useQuery(['activity.release', id], () => service.getReleaseActivities(id), options);
}

export function useGetEnvActivities(id, options = {}) {
  const service = useService(SERVICE_KEYS.ACTIVITY);
  return useQuery(['activity.env', id], () => service.getEnvActivities(id), options);
}

export function useGetSystemActivities(options = {}) {
  const service = useService(SERVICE_KEYS.ACTIVITY);
  return useQuery(['activity.system'], () => service.getSystemActivities(), options);
}

import { Button, Drawer, Modal, Spin, message } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useImperativeHandle, useRef, useState } from 'react';
import {
  useCreateSystemEnv,
  useDeleteSystemEnv,
  useInspectEnv,
  useUpdateSystemEnv,
} from '../../../hooks/k8s/envs';

import { Activities } from '../../../containers/Activities';
import { ChartExplorer } from './ChartExplorer';
import { EnvForm } from '../components/EnvForm';
import { omit } from 'lodash';
import { useGetEnvActivities } from '../../../hooks/activity';

const EDITOR_MODES = {
  SYSTEM: 'system',
  ORGANIZATION: 'organization',
};

export const EnvEditor = React.forwardRef(function EnvEditor(
  { mode = EDITOR_MODES.SYSTEM, onUpdated, onCreated, onDeleted, onUpdateOrgEnvs },
  ref,
) {
  const form = useRef();
  const exprorer = useRef();
  const [chart, setChart] = useState(false);
  const [selected, setSelected] = useState(false);

  const onOpenChartModal = () => setChart(true);
  const onCloseChartExplorer = () => setChart(false);
  const onSetValuesFromChart = () => {
    setChart(false);
    const values = exprorer.current?.values || {};
    form.current?.setFieldsValue({ values: JSON.stringify(values, null, 2) });
  };

  const delete$ = useDeleteSystemEnv({
    onSuccess: () => {
      if (onDeleted) {
        onDeleted();
      }

      message.success('Env deleted!');
      return onClose();
    },
  });

  const create$ = useCreateSystemEnv({
    onSuccess: () => {
      if (onCreated) {
        onCreated();
      }

      message.success('Env created!');
      return onClose();
    },
  });

  const update$ = useUpdateSystemEnv({
    onSuccess: () => {
      if (onUpdated) {
        onUpdated();
      }

      message.success('Env updated!');
      return onClose();
    },
  });

  // Works for saved env
  const inspect$ = useInspectEnv(selected?.id, {
    enabled: !!selected && !!selected.id,
  });

  const onClose = () => setSelected(null);
  const onOpen = (item) => setSelected(item);
  useImperativeHandle(ref, () => ({ open: onOpen, close: onClose }));

  const onDelete = () =>
    Modal.confirm({
      okText: 'Delete',
      title: 'Delete env',
      onOk: () => delete$.mutate(selected?.id),
      content: 'Ayou you sure to delete this env?',
      okButtonProps: { danger: true, type: 'primary' },
    });
  const onClone = () => {
    let cloned = omit(selected, ['id']);
    cloned.name = `${cloned.name} - copy`;

    setSelected(cloned);
    form.current.setFieldsValue(cloned);
  };
  const onSave = () => {
    const values = form.current?.getFieldsValue();
    const isNew = !!selected && !selected.id;

    if (mode === EDITOR_MODES.ORGANIZATION) {
      return onUpdateOrgEnvs({ ...values, isNew, id: selected.id });
    }

    if (isNew) {
      create$.mutate(values);
    } else {
      update$.mutate({ ...values, id: selected.id });
    }
  };

  const isLoading =
    inspect$.isLoading || create$.isLoading || update$.isLoading || delete$.isLoading;

  const activities$ = useGetEnvActivities(selected?.id, {
    enabled: !!selected?.id,
  });

  const extra = (
    <div className="mr-3 flex-grow flex-row flex items-center">
      {selected?.id && mode === EDITOR_MODES.SYSTEM ? (
        <>
          <Button
            loading={delete$.isLoading}
            onClick={onDelete}
            className="flex flex-row items-center mr-3"
            icon={<DeleteOutlined />}
            danger
          >
            Delete
          </Button>
          <Button
            disabled={isLoading}
            className="flex flex-row items-center"
            onClick={onClone}
            icon={<CopyOutlined />}
          >
            Clone
          </Button>
        </>
      ) : null}
    </div>
  );

  const toolbar = (
    <div>
      <Button disabled={isLoading} onClick={onClose} className="mr-3">
        Close
      </Button>
      <Button loading={create$.isLoading || update$.isLoading} type="primary" onClick={onSave}>
        Save
      </Button>
    </div>
  );

  const footer = (
    <div className="flex w-full items-center flex-row">
      {extra}
      {toolbar}
    </div>
  );

  return (
    <>
      <Drawer
        width="40%"
        destroyOnClose
        footer={footer}
        onClose={onClose}
        maskClosable={false}
        visible={!!selected}
        title={selected?.id ? 'Edit environment' : 'Add new environment'}
      >
        <Spin spinning={isLoading}>
          {inspect$.isLoading ? (
            <div className="h-full items-center w-full flex justify-center">
              <Spin />{' '}
            </div>
          ) : (
            <EnvForm
              skipGeneralFields={mode === EDITOR_MODES.ORGANIZATION}
              onPullFromChart={onOpenChartModal}
              env={inspect$.data}
              ref={form}
            />
          )}
        </Spin>

        {selected?.id ? <Activities query$={activities$} /> : null}
      </Drawer>

      <Modal
        closable
        destroyOnClose
        visible={chart}
        onOk={onSetValuesFromChart}
        title="Pull envs from chart"
        onCancel={onCloseChartExplorer}
      >
        <ChartExplorer ref={exprorer} />
      </Modal>
    </>
  );
});

import { Button, Form, Input } from 'antd';
import React, { useImperativeHandle, useRef } from 'react';

import Editor from '@monaco-editor/react';

export const EnvForm = React.forwardRef(function EnvForm(
  { env, skipGeneralFields = false, size = 'large', onPullFromChart },
  ref,
) {
  const form = useRef();

  useImperativeHandle(ref, () => form.current);

  const extra = (
    <div className="flex flex-row items-center justify-between text-xs">
      <span className="text-gray-400">Env variables in JSON format</span>
      <Button onClick={onPullFromChart} className="p-0 text-xs h-auto" type="link">
        <span className="underline">Pull from helm chart</span>
      </Button>
    </div>
  );

  return (
    <Form ref={form} layout="vertical" validateTrigger={['onBlur', 'onSubmit']}>
      <Form.Item hidden initialValue={env?.id} />
      {skipGeneralFields ? null : (
        <>
          <Form.Item
            initialValue={env?.name}
            required
            rules={[{ required: true }]}
            name="name"
            label="Name"
          >
            <Input />
          </Form.Item>
          <Form.Item initialValue={env?.description} name="description" label="Description">
            <Input />
          </Form.Item>
        </>
      )}
      <Form.Item
        extra={extra}
        initialValue={env?.values}
        required
        rules={[{ required: true }]}
        name="values"
        label="Values"
      >
        <Editor
          height={skipGeneralFields ? '1100px' : '920px'}
          className="h-full pb-1"
          theme="vs-dark"
          defaultLanguage="json"
        />
      </Form.Item>
    </Form>
  );
});

import { useMutation, useQuery } from 'react-query';

import { SERVICE_KEYS } from '../../constants/services';
import { useService } from '../../context/services';

export function useGetOrganizations(filters, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['organizations', filters], () => service.getOrganizations(filters), options);
}

export function useGetReleaseStatus(organization, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(
    ['organization.release', { organization }],
    () => service.getReleaseStatus(organization),
    {
      ...options,
      refetchOnWindowFocus: false,
    },
  );
}

export function useGetOrganizationHealthStatus(organization, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(
    ['organization.health', { organization }],
    () => service.getHealthStatus(organization),
    {
      ...options,
      refetchOnWindowFocus: false,
    },
  );
}

export function useDeleteOrganization(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.deleteOrganization(payload), options);
}

export function useUpdateOrganizationDeployment(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.patchOrganizationDeployment(payload), options);
}

export function useUpdateOrganizationInfo(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.updateOrganizationInfo(payload), options);
}

export function useUpdateOrganizationEnvs(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.patchOrganizationEnv(payload), options);
}

export function useGetOrganizationInfo(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['organizations.info', { id }], () => service.getOrganizationInfo(id), options);
}

export function useAddOrganization(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.addOrganization(payload), options);
}

export function useGetOrganizationBackups(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(
    ['organizations.backups', { id }],
    () => service.getOrganizationBackups(id),
    options,
  );
}

export function useGetOrganizationDeploymentValues(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['organizations.release', { id }], () => service.releaseValues(id), options);
}

export function useGetReleasePods(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['organizations.release-pods', { id }], () => service.pods(id), options);
}

export function useCreateOrganizationBackup(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((id) => service.createBackup(id), options);
}

export function useRestoreOrganizationBackup(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.restoreBackup(payload), options);
}

export function useRestartPod(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.restartPod(payload), options);
}

export function useScaleOrganization(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.scale(payload), options);
}

export function useDbConnectionTest(options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useMutation((payload) => service.dbConnection(payload), options);
}

export function useGetOrganizationUsers(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['organizations-users', { id }], () => service.getOrganizationUsers(id), options);
}

export function useGetActiveUsers(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['active-users', { id }], () => service.getActiveUsers(id), options);
}

export function useGetLoggedUsers(id, options = {}) {
  const service = useService(SERVICE_KEYS.ORGANIZATIONS);
  return useQuery(['logged-users', { id }], () => service.getLoggedUsers(id), options);
}

import { Col, Form, Row, Select } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useGetDockerImageTags, useGetDockerImages } from '../../../../hooks/k8s/docker';

import { kebabCase } from 'lodash';

export const ChangeVersionForm = forwardRef(function ChangeVersionForm(
  { organization = {}, size = 'large', onFinish },
  ref,
) {
  const form = useRef();
  const [image, setImage] = useState(organization.image);

  useImperativeHandle(ref, () => form.current);

  const images$ = useGetDockerImages();
  const tags$ = useGetDockerImageTags(image, { enabled: !!image });

  const onValuesChange = (updates) => {
    if (updates.image) {
      setImage(updates.image);
    }

    if (updates.name) {
      form.current?.setFieldsValue({ subdomain: kebabCase(updates.name) });
    }
  };

  return (
    <Form
      onFinish={onFinish}
      validateTrigger={['onBlur', 'onSubmit']}
      onValuesChange={onValuesChange}
      layout="vertical"
      ref={form}
    >
      <Form.Item hidden name="organization" initialValue={organization.id} />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            initialValue={organization.image}
            label="Image"
            name="image"
            required
            rules={[{ required: true }]}
          >
            <Select className="w-full" showSearch>
              {images$.data?.map((record) => (
                <Select.Option value={record.image}>{record.image}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            initialValue={organization.appVersion}
            label="Version"
            name="appVersion"
            required
            rules={[{ required: true }]}
          >
            <Select
              disabled={!image}
              loading={tags$.isLoading || images$.isLoading}
              name="tag"
              className="w-full"
              showSearch
            >
              {tags$.data?.tags.map((tag) => (
                <Select.Option value={tag.name}>{tag.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export class DeploymentsService {
  constructor(api) {
    this.api = api;
  }

  /**
   *
   * @param {Object} filters
   * @returns
   */
  deployments(filters) {
    return this.api.get('/deployment', { params: filters }).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} filters
   * @returns
   */
  find(id) {
    return this.api.get(`/deployment/${id}`).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} filters
   * @returns
   */
  filters() {
    return this.api.get(`/deployment/filters`).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} payload
   * @returns
   */
  update({ release, values }) {
    return this.api.put(`/deployment/${release}`, { values }).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} filters
   * @returns
   */
  releaseStatus(id) {
    return this.api.get(`/deployment/release-status/${id}`).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} payload
   * @returns
   */
  deployRelease(payload) {
    return this.api.post('/deployment', payload).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} payload
   * @returns
   */
  pods(release) {
    return this.api.get(`/deployment/pods/${release}`).then(({ data }) => data);
  }

  /**
   *
   * @param {Object} payload
   * @returns
   */
  services(release) {
    return this.api.get(`/deployment/services/${release}`).then(({ data }) => data);
  }

  /**
   *
   * @param {string} chart
   * @returns
   */
  chart(chart) {
    return this.api
      .get('/deployment/inspect/chart', { params: { chart } })
      .then(({ data }) => data);
  }

  /**
   *
   * @param {string} release
   * @returns
   */
  release(release) {
    return this.api
      .get('/deployment/inspect/release', { params: { release } })
      .then(({ data }) => data);
  }

  /**
   *
   * @param {string} release
   * @returns
   */
  deleteRelease(release) {
    return this.api.delete(`/deployment/${release}`).then(({ data }) => data);
  }
}

import { Alert, Select } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { useGetCharts } from '../../../hooks/k8s/system';
import { useInspectChartEnvs } from '../../../hooks/k8s/envs';

export const ChartExplorer = forwardRef(function ChartExplorer({ size = 'large' }, ref) {
  const [chart, setChart] = useState();
  const charts$ = useGetCharts();

  const inspect$ = useInspectChartEnvs(chart, {
    enabled: !!chart,
  });

  useImperativeHandle(ref, () => ({ values: inspect$.data }));

  return (
    <div>
      <Select className="w-full mb-5" onSelect={setChart} loading={charts$.isLoading}>
        {(charts$.data || []).map((repo) => (
          <Select.OptGroup label={repo.repository}>
            {repo.charts?.map((chart) => (
              <Select.Option value={`${repo.repository}/${chart.name}`}>
                {`${repo.repository}/${chart.name}`}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
      {inspect$.data && (
        <pre className="bg-gray-100 px-3 py-5">{JSON.stringify(inspect$.data, null, 2)}</pre>
      )}
      {inspect$.isFetched && !inspect$.data ? (
        <Alert message="No 'env' values in this chart" />
      ) : null}
    </div>
  );
});

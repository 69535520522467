export class K8Service {
  constructor(api) {
    this.api = api;
  }

  // Repositories management
  getDockerImages() {
    return this.api.get('docker/repositories').then(({ data }) => data);
  }

  // Repositories management
  getDockerImageTags(image) {
    return this.api.get(`docker/${image}/tags`).then(({ data }) => data);
  }

  // Repositories management
  getRepositories() {
    return this.api.get('k8s/repositories').then(({ data }) => data);
  }

  addRepository(payload) {
    return this.api.post('k8s/repositories', payload).then(({ data }) => data);
  }

  syncAllRepositories(payload) {
    return this.api.get('k8s/repositories/update', payload).then(({ data }) => data);
  }

  inspectChart(chart) {
    return this.api.get(`k8s/charts/inspect?chart=${chart}`).then(({ data }) => data);
  }

  // Release management
  getReleaseStatus() {}

  installRelease() {}

  uninstallRelease() {}

  updateRelease() {}
}

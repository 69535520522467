import { SERVICE_KEYS } from '../constants/services';

import { K8Service } from './K8Service';
import { EnvService } from './EnvService';
import { SystemService } from './SystemService';
import { OrganizationsService } from './OrganizationsService';
import { DeploymentsService } from './DeploymentsService';
import { ActivityService } from './ActivityService';
import { VendorsService } from './VendorsService';

export const createServices = ({ axios }) => ({
  [SERVICE_KEYS.K8S]: new K8Service(axios),
  [SERVICE_KEYS.ENVS]: new EnvService(axios),
  [SERVICE_KEYS.SYSTEM]: new SystemService(axios),
  [SERVICE_KEYS.VENDORS]: new VendorsService(axios),
  [SERVICE_KEYS.ACTIVITY]: new ActivityService(axios),
  [SERVICE_KEYS.DEPLOYMENTS]: new DeploymentsService(axios),
  [SERVICE_KEYS.ORGANIZATIONS]: new OrganizationsService(axios),
});

import { Badge, Tag, Tooltip } from 'antd';

import { useGetSystemConnection } from '../../../hooks/k8s/system';
import { useMemo } from 'react';

export function ConnectionStatus() {
  const connection = useGetSystemConnection({
    refetchInterval: 10000,
    keepPreviousData: true,
  });

  const color = useMemo(() => {
    switch (connection.status) {
      case 'error':
        return 'error';
      case 'success':
        return 'success';
      default:
        return 'blue';
    }
  }, [connection.status]);

  const badge = useMemo(() => {
    switch (connection.status) {
      case 'error':
        return 'red';
      case 'success':
        return 'green';
      default:
        return 'blue';
    }
  }, [connection.status]);

  const tooltip = useMemo(() => {
    switch (connection.status) {
      case 'error':
        return 'Can not connect to K8S cluster';
      case 'success':
        return 'Connection ready for a work';
      default:
        return 'Checking k8s connection';
    }
  }, [connection.status]);

  const text = useMemo(() => {
    switch (connection.status) {
      case 'error':
        return 'AKS Connection - error';
      case 'success':
        return 'AKS Connection - OK';
      default:
        return 'AKS Connection - Loading...';
    }
  }, [connection.status]);

  return (
    <Tooltip trigger={['hover']} overlay={tooltip}>
      <Tag color={color}>
        <Badge color={badge} /> &nbsp;
        <span>{text}</span>
      </Tag>
    </Tooltip>
  );
}

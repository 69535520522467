import { Col, Form, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useGetDockerImageTags, useGetDockerImages } from '../../../../../hooks/k8s/docker';

import { useGetCharts } from '../../../../../hooks/k8s/system';
import { useGetSystemEnvs } from '../../../../../hooks/k8s/envs';

export function NewOrganizationDeploymentInfo({
  organizationData = {},
  setOrganizationData,
  setNextStepEnabled,
}) {
  const [deployment, setDeployment] = useState(organizationData.deployment);

  console.debug('deployment', deployment);
  const envs = useGetSystemEnvs();
  const charts = useGetCharts();
  const dockerImages = useGetDockerImages();
  const dockerImageTags = useGetDockerImageTags(deployment.image);
  const [form] = Form.useForm();

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        NewOrganizationDeploymentInfo.validator({}, deployment)
          .then(() => {
            setNextStepEnabled(true);
          })
          .catch((err) => {
            console.debug(err);
            setNextStepEnabled(false);
          });
      },
      () => {
        setNextStepEnabled(false);
      },
    );
  }, [form, setNextStepEnabled, deployment]);

  const onDeploymentDataChange = (data) => {
    organizationData.deployment = { ...deployment, ...data };
    setOrganizationData(organizationData);
    setDeployment(organizationData.deployment);
  };

  return (
    <Form
      form={form}
      validateTrigger={['onBlur']}
      onValuesChange={onDeploymentDataChange}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        name="envId"
        label="Environment"
        initialValue={deployment.envId}
        rules={[{ required: true }]}
      >
        <Select loading={envs.isLoading}>
          {(envs.data || []).map((env) => (
            <Select.Option value={env.id}>{env.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <ChartSelector deployment={deployment} charts={charts} />
        </Col>
        <Col span={12}>
          <ChartVersionSelector deployment={deployment} charts={charts} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <DockerImageSelector deployment={deployment} dockerImages={dockerImages} />
        </Col>
        <Col span={12}>
          <DockerImageTagSelector deployment={deployment} dockerImageTags={dockerImageTags} />
        </Col>
      </Row>
    </Form>
  );
}

const ChartSelector = ({ deployment, charts = [] }) => {
  return (
    <Form.Item
      name="chart"
      label="Chart"
      initialValue={deployment?.chart}
      rules={[{ required: true }, { min: 5, max: 32 }]}
    >
      <Select showSearch loading={charts.isLoading} disabled={charts.isLoading}>
        {charts.data?.map((repo) => (
          <Select.OptGroup key={repo.repository} label={repo.repository}>
            {repo.charts?.map((chart) => {
              const value = `${repo.repository}/${chart.name}`;
              return (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
};

const ChartVersionSelector = ({ deployment, charts = [] }) => {
  let selectedChartVersions = [];
  if (!charts.isLoading && deployment?.chart && charts.data && charts.data.length > 0) {
    const [repo, name] = deployment?.chart.split('/');
    const repository = charts.data.find(({ repository }) => repository === repo);

    if (repository) {
      const record = (repository.charts || []).find((item) => item.name === name);
      selectedChartVersions = record?.versions.map(({ version }) => ({
        label: version,
        value: version,
      }));
    }
  }

  return (
    <Form.Item
      name="chartVersion"
      label="Chart version"
      initialValue={deployment.chartVersion}
      rules={[{ required: true }]}
    >
      <Select
        loading={charts.isLoading}
        disabled={charts.isLoading || !selectedChartVersions || selectedChartVersions.length === 0}
        options={selectedChartVersions}
      />
    </Form.Item>
  );
};

const DockerImageSelector = ({ deployment, dockerImages = [] }) => {
  return (
    <Form.Item
      name="image"
      label="Docker image"
      initialValue={deployment.image}
      rules={[{ required: true }]}
    >
      <Select
        loading={dockerImages.isLoading}
        disabled={dockerImages.isLoading}
        options={dockerImages.data
          ?.filter((dockerImage) => dockerImage.image.includes('csi-lawyer-app', 'csi-trustee-app'))
          .map(({ image }) => ({ value: image, label: image }))}
      />
    </Form.Item>
  );
};

const DockerImageTagSelector = ({ deployment, dockerImageTags = [] }) => {
  return (
    <Form.Item
      name="appVersion"
      label="Image tag"
      initialValue={deployment.appVersion}
      rules={[{ required: true }]}
    >
      <Select
        loading={dockerImageTags.isLoading}
        disabled={dockerImageTags.isLoading || !deployment.image}
        options={dockerImageTags.data?.tags
          ?.filter(({ name }) => name !== 'latest')
          .map(({ name }) => ({ value: name, label: name }))}
      />
    </Form.Item>
  );
};

NewOrganizationDeploymentInfo.validator = function validateDeploymentInfo(rule, value = {}) {
  if (value.chart && value.chartVersion && value.image && value.appVersion && value.envId) {
    return Promise.resolve();
  }

  return Promise.reject(new Error(`Please check deployment settings...`));
};

import { Button, Result, Spin } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AuthProvider } from './../context/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export function Auth({ children }) {
  const { loginWithRedirect, isLoading, isAuthenticated, logout, error } = useAuth0();
  const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE || 'read:current_user';

  const onSignOut = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  if (error) console.debug({ error });

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        onError: (err) => {
          console.error(err);
        },
      },
    },
  });

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          scope: auth0Scope,
        },
      });
    }
  }, [auth0Scope, isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <Result
          icon={<Spin />}
          title="Logging in..."
          subTitle="Please sign out if auth stuck"
          extra={<Button onClick={onSignOut}>Sign out</Button>}
        />
      </div>
    );
  }

  if (!isAuthenticated || !!error) {
    return (
      <Result
        status="403"
        title="Not authenticated"
        subTitle="Please reload app and process sign in"
        extra={
          <>
            <Button onClick={() => window.location.reload()}>Reload</Button>
          </>
        }
      />
    );
  }

  return (
    <QueryClientProvider client={client}>
      <AuthProvider>{children}</AuthProvider>
    </QueryClientProvider>
  );
}

import { Button, Divider, Form, List, message, Modal, Select } from 'antd';

import { useCallback, useMemo, useState } from 'react';
import {
  useGetApplicationOrganizations,
  useGetAvailableOrganizations,
  useLinkOrganizationToApplication,
  useUnlinkOrganizationFromApplication,
} from '../../../hooks/vendors/vendors-management';
import _ from 'lodash';
import SearchInput from '../../../components/SearchInput';

export function ConnectedOrganizationsContainer({ application = {} }) {
  const [form] = Form.useForm();

  const [availableOrganizationsSearchValue, setAvailableOrganizationsSearchValue] = useState('');
  const [connectedOrganizationsSearchValue, setConnectedOrganizationsSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const availableOrganizations = useGetAvailableOrganizations(
    application.id,
    { search: availableOrganizationsSearchValue },
    {
      enabled: !!application.id && availableOrganizationsSearchValue.length > 2,
    },
  );
  const applicationOrganizations = useGetApplicationOrganizations(
    application.id,
    { search: connectedOrganizationsSearchValue, page: currentPage, limit: 5 },
    {
      enabled: !!application.id,
    },
  );

  const linkOrganizations = useLinkOrganizationToApplication({
    onSuccess: () => {
      form.resetFields();

      applicationOrganizations.refetch();

      message.success('Organizations linked');
    },
  });

  const unlinkOrganization = useUnlinkOrganizationFromApplication({
    onSuccess: () => {
      form.resetFields();

      applicationOrganizations.refetch();

      message.success('Organization unlinked');
    },
  });

  const availableOrgs = useMemo(() => {
    const filteredOrgs = availableOrganizations.data?.items?.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    return filteredOrgs;
  }, [availableOrganizations.data]);

  const onLinkOrg = async (applicationId, organizationIds) => {
    await linkOrganizations.mutateAsync({
      applicationId: applicationId,
      organizationIds: organizationIds,
    });
  };

  const onUnlinkOrg = (applicationId, organizationId) => {
    Modal.confirm({
      title: `Are you sure you want to unlink this organization from application?`,
      okButtonProps: { danger: true },
      onOk: () => unlinkOrganization.mutateAsync({ applicationId, organizationId }),
    });
  };

  const debouncedSearch = useCallback(_.debounce(setAvailableOrganizationsSearchValue, 200), []);

  return (
    <Form
      form={form}
      onFinish={async (values) => {
        if (!values.organizationIds) {
          return;
        }

        const ids = values.organizationIds?.map((org) =>
          typeof org === 'object' ? org.value : org,
        );

        await onLinkOrg(application.id, ids);
      }}
      layout="vertical"
      className="flex flex-col mt-5 border border-gray-200 p-5 rounded-lg"
    >
      <p className="pb-3">Select organizations to link:</p>

      <div className="flex flex-row items-center space-x-10">
        <Form.Item name="organizationIds" className="flex-1">
          <Select
            allowClear
            placeholder="Search for organizations to link (min 3 characters)"
            options={
              availableOrganizations.isFetched &&
              availableOrganizationsSearchValue?.length > 2 &&
              availableOrgs
            }
            mode="multiple"
            onSearch={(value) => debouncedSearch(value)}
            onBlur={() => setAvailableOrganizationsSearchValue('')}
            loading={availableOrganizations.isFetching}
            filterOption={false}
          />
        </Form.Item>
        <Form.Item className="">
          <Button className="w-32" htmlType="submit" type="primary">
            Link
          </Button>
        </Form.Item>
      </div>
      <Divider />
      <List
        header={
          <div className="flex flex-row justify-between">
            <div>Connected organizations</div>
            <div className="mr-2">
              <SearchInput
                placeholder="Search connected organizations"
                onSearch={(value) => setConnectedOrganizationsSearchValue(value)}
                onChange={(value) => setConnectedOrganizationsSearchValue(value)}
              />
            </div>
          </div>
        }
        pagination={{
          pageSize: 5,
          onChange: (page) => setCurrentPage(page),
          total: applicationOrganizations.data?.meta?.totalItems,
        }}
        style={{ padding: 0 }}
        loading={applicationOrganizations.isLoading || applicationOrganizations.isFetching}
        dataSource={
          applicationOrganizations.data?.items ? applicationOrganizations.data?.items : []
        }
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key={item.id}
                onClick={() => {
                  onUnlinkOrg(application.id, item.id);
                }}
                type="primary"
              >
                Unlink
              </Button>,
            ]}
          >
            <List.Item.Meta title={item.name} description={item.namespace} />
          </List.Item>
        )}
      />
    </Form>
  );
}

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Drawer, Form, Input, List, Modal, Typography, message } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  useCreateSystemDomain,
  useDeleteSystemDomain,
  useGetSystemDomains,
  useUpdateSystemDomain,
} from '../../../hooks/k8s/system';

import { DomainForm } from '../components/DomainForm';

export function DomainsList() {
  const form$ = useRef();
  const [remove, setRemove] = useState(false);

  const list$ = useGetSystemDomains();
  const create$ = useCreateSystemDomain({
    onSuccess: () => {
      list$.refetch();
      form$.current?.reset();
      message.success('Domain added!');
    },
  });
  const update$ = useUpdateSystemDomain({
    onSuccess: () => {
      list$.refetch();
      form$.current?.reset();
      message.success('Domain updated!');
    },
  });

  const onSubmit = (values = {}) => {
    if (!values.id) {
      create$.mutate(values);
    } else {
      update$.mutate(values);
    }
  };

  const onDelete = (item) => () => setRemove(item);
  const onEdit = (item) => () => form$.current?.set(item);

  return (
    <div>
      <DomainForm ref={form$} onSubmit={onSubmit} />
      <List
        bordered
        rowKey="id"
        className="mt-2"
        pagination={null}
        header={<span className="font-semibold text-base">Application domains</span>}
        loading={list$.isLoading}
        dataSource={list$.data || []}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            className="cursor-pointer hover:bg-gray-50 flex-col justify-start "
          >
            <div className="items-center flex flex-row w-full">
              <div className="flex-grow">
                <Typography.Text className="mr-2">{item.domain}</Typography.Text>
              </div>
              <div>
                <EditOutlined
                  onClick={onEdit(item)}
                  className="text-blue-500 opacity-50 hover:opacity-100"
                />
                <span className="mx-1" />
                <DeleteOutlined
                  onClick={onDelete(item)}
                  className="text-red-500 opacity-50 hover:opacity-100"
                />
              </div>
            </div>
            <div className="w-full">
              {(item.subdomains || []).map((sub) => (
                <span className="mr-2 text-gray-400 text-xs">
                  {sub}.{item.domain}
                </span>
              ))}
            </div>
          </List.Item>
        )}
      />

      <DomainsList.DeleteForm
        visible={true}
        id={remove?.id}
        domain={remove?.domain}
        visible={!!remove?.domain}
        onCancel={() => setRemove(null)}
        onDeleted={() => list$.refetch()}
      />
    </div>
  );
}

DomainsList.DeleteForm = function DeleteForm({ domain, id, onDeleted, ...props }) {
  const form = useRef();
  const delete$ = useDeleteSystemDomain({
    onSuccess: () => {
      onDeleted();
      props.onCancel();
      message.success('Domain deleted!');
    },
    onError: () => {
      message.error('Domain can not be deleted!');
    },
  });

  const onDeleteDomain = (values) => delete$.mutate(values);

  return (
    <Modal
      {...props}
      title="Delete domain"
      onOk={() => form.current?.submit()}
      destroyOnClose
      okButtonProps={{ danger: true, loading: delete$.isLoading }}
    >
      <Typography className="mb-2">
        You're about delete linked domain - <strong>{domain}</strong>. Please confirm domain name in
        for below:
      </Typography>
      <Form onFinish={onDeleteDomain} ref={form} layout="vertical">
        <Form.Item name="id" hidden initialValue={id} />
        <Form.Item name="domain" className="mb-0" required rules={[{ required: true }]}>
          <Input placeholder="example.com" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

DomainsList.Drawer = forwardRef(function DomainsListDrawer(props, ref) {
  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible(!visible);

  useImperativeHandle(ref, () => ({ toggle }));

  return (
    <Drawer
      onClose={toggle}
      width={630}
      title="Domains management"
      destroyOnClose
      footer={null}
      visible={visible}
    >
      <DomainsList />
    </Drawer>
  );
});

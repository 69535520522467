import { Button, Form, Layout, Select, Table } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { capitalize, startCase } from 'lodash';
import {
  useGetDeployedReleases,
  useGetDeploymentFilters,
} from '../../../hooks/deployments/deployments';
import { useRef, useState } from 'react';

import { APP_SCOPES } from '../../../constants/services';
import { DeploymentForm } from '../containers/DeploymentForm';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { withPermissions } from '../../../context/permissions';

export const Deployments = withPermissions([APP_SCOPES.RELEASES], function Deployments() {
  const form$ = useRef();
  const drawer$ = useRef();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({});

  const filters$ = useGetDeploymentFilters();
  const list$ = useGetDeployedReleases(filters);

  const onSelectRelease = (id) => navigate(`/deployments/${id}`, { id: id });

  return (
    <div className="bg-white min-h-full shadow-md overflow-y-auto">
      <PageHeader
        title="Deployments"
        extra={
          <>
            <Button
              className="ant-select-selection-item-remove"
              icon={<PlusOutlined />}
              onClick={() => drawer$.current?.open()}
            >
              New deployment
            </Button>
          </>
        }
      />
      <Form
        ref={form$}
        layout="inline"
        className="px-5 mb-3 flex flex-row w-full"
        onFinish={setFilters}
      >
        <Form.Item initialValue="" label="Chart" style={{ minWidth: '220px' }} name="chart">
          <Select allowClear showSearch>
            {(filters$.data?.charts || []).map((chart) => (
              <Select.Option value={chart} key={chart}>
                {chart}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item initialValue="" label="Namespace" className="w-60" name="namespace">
          <Select allowClear showSearch>
            {(filters$.data?.namespaces || []).map((ns) => (
              <Select.Option value={ns} key={ns}>
                {ns}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item initialValue="" label="Status" className="w-60" name="status">
          <Select allowClear showSearch>
            {(filters$.data?.statuses || []).map((status) => (
              <Select.Option value={status} key={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Apply
          </Button>
          <Button htmlType="button" className="ml-2" onClick={() => form$.current?.resetFields()}>
            Reset
          </Button>
        </Form.Item>
      </Form>
      <Layout.Content style={{ padding: '0 15px' }}>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          rowClassName="cursor-pointer"
          dataSource={list$.data || []}
          onRow={({ id }) => ({
            onClick: () => onSelectRelease(id),
          })}
          columns={[
            {
              title: 'Release',
              dataIndex: 'name',
              render: (name, { chart, status }) => (
                <div>
                  <p className="inline-flex items-center">
                    {status === 'not-deleted' ? (
                      <ExclamationCircleOutlined className="text-red-400 mr-2" />
                    ) : null}
                    {name}
                  </p>
                  <p className="text-gray-400 text-xs">{chart}</p>
                </div>
              ),
            },
            {
              title: 'Owner',
              dataIndex: 'ownerName',
              render: (name, { ownerEmail }) => (
                <div>
                  <p>{name}</p>
                  <p className="text-gray-400 text-xs">
                    <a onClick={(e) => e.stopPropagation()} href={`mailto:${ownerEmail}`}>
                      {ownerEmail}
                    </a>
                  </p>
                </div>
              ),
            },
            {
              title: 'Lifecycle',
              dataIndex: 'updatedAt',
              render: (updatedAt, { status }) => (
                <div>
                  <p className="text-gray-400 text-xs">Status: {capitalize(startCase(status))}</p>
                  <p className="text-gray-400 text-xs">
                    Last update: {moment(updatedAt).format('L LT')}
                  </p>
                </div>
              ),
            },
          ]}
        />
      </Layout.Content>
      <DeploymentForm ref={drawer$} onDeployed={() => list$.refetch()} />
    </div>
  );
});

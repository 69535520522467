import { Button, Card, Col, message, Modal, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { APP_SCOPES } from '../../../constants/services';
import { PageHeader } from '@ant-design/pro-layout';
import { useRef, useState } from 'react';
import { useIsCan, withPermissions } from '../../../context/permissions';
import {
  useDeleteVendor,
  useGetVendor,
  useUpdateVendor,
} from '../../../hooks/vendors/vendors-management';
import { VendorSummary } from '../components/VendorSummary';
import { Activities } from '../../../containers/Activities';
import { useGetVendorActivities } from '../../../hooks/activity';
import { VendorForm } from '../components/VendorForm';

import useInvalidateVendorActivityQueries from '../hooks/useInvalidateVendorActivityQueries';
import { StatusComponent } from '../components/StatusComponent';

export const VendorsDrillDown = withPermissions([APP_SCOPES.VENDORS], function VendorsDrilldown() {
  const navigate = useNavigate();
  const { id } = useParams();
  const vendor = useGetVendor(id);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const invalidateVendorActivityQueries = useInvalidateVendorActivityQueries();
  const can = useIsCan();

  const update$ = useUpdateVendor({
    onSuccess: () => {
      vendor.refetch();
      setEditModalVisible(false);
      message.success('Vendor updated');
      invalidateVendorActivityQueries();
    },
    onError: (error) => {
      if (error?.response?.data?.message?.includes('duplicate')) {
        message.error('Vendor identifier is already in use');
      } else {
        message.error(error?.response?.data?.message || 'An error occurred');
      }
    },
  });

  const delete$ = useDeleteVendor({
    onSuccess: () => {
      navigate(`/vendors`);

      message.success('Vendor deleted');
    },
  });

  const onDelete = () =>
    Modal.confirm({
      okText: 'Yes',
      type: 'warning',
      okType: 'danger',
      cancelText: 'No',
      title: 'Delete vendor?',
      onOk: () => delete$.mutateAsync(id),
      content: 'This deployment will be deleted with all resources! Are you sure?',
    });

  const activities$ = useGetVendorActivities(vendor.data?.id, { enabled: !!vendor.data?.id });

  const onFormSubmit = (values) => {
    if (values.id) {
      return update$.mutate({ vendorId: values.id, payload: values });
    }
  };

  const changeVendorStatus = () => {
    return update$.mutate({
      vendorId: vendor.data.id,
      payload: { status: vendor.data.status === 'active' ? 'inactive' : 'active' },
    });
  };

  const form = useRef();

  return (
    <div className="bg-white min-h-full shadow-md overflow-y-auto px-5">
      <PageHeader
        onBack={() => navigate('/vendors')}
        title={vendor.data?.name}
        subTitle={<StatusComponent status={vendor.data?.status} />}
        className="mb-5 border-b-1 border-gray-100"
        extra={
          <Button disabled={!can([APP_SCOPES['DELETE_VENDORS']])} onClick={onDelete} danger ghost>
            Delete
          </Button>
        }
      />
      <Row gutter={16}>
        <Col span={16}>
          <VendorSummary
            vendor={vendor.data || {}}
            isFetched={vendor.isFetched}
            isLoading={vendor.isLoading}
            editVendor={() => setEditModalVisible(true)}
            changeVendorStatus={() => changeVendorStatus()}
          />
        </Col>
        <Col span={8}>
          <Card>
            {vendor?.data?.id ? <Activities title={'Change history'} query$={activities$} /> : null}
          </Card>
        </Col>
      </Row>

      <Modal
        confirmLoading={false}
        closable
        open={editModalVisible}
        width={'80%'}
        onCancel={() => {
          setEditModalVisible(false);
        }}
        onOk={() => form.current?.submit()}
        okText={'Edit'}
        destroyOnClose
        title={'Edit vendor'}
      >
        <VendorForm ref={form} onFinish={onFormSubmit} vendor={vendor.data} />
      </Modal>
    </div>
  );
});

import 'antd/dist/reset.css';

import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';

/* Disable console.debug in production */
if (process.env.NODE_ENV !== 'development') {
  console.debug = function () {};
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

import { SERVICE_KEYS } from '../../constants/services';
import { useQuery } from 'react-query';
import { useService } from '../../context/services';

export function useGetDockerImages(options = {}) {
  const k8s = useService(SERVICE_KEYS.K8S);
  return useQuery('docker.repositories', () => k8s.getDockerImages(), options);
}

export function useGetDockerImageTags(image, options = {}) {
  const k8s = useService(SERVICE_KEYS.K8S);
  return useQuery(['image.tags', { image }], () => k8s.getDockerImageTags(image), options);
}

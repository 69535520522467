import { APP_SCOPES } from '../../../constants/services';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { VendorDrawer } from '../containers/VendorDrawer';
import { VendorsList } from '../containers/VendorsList';
import { useRef, useState } from 'react';
import { withPermissions } from '../../../context/permissions';
import SearchInput from '../../../components/SearchInput';

export const Vendors = withPermissions([APP_SCOPES.VENDORS], function Vendors() {
  const form$ = useRef();

  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="bg-white min-h-full shadow-md overflow-y-auto">
      <PageHeader
        title="Vendors"
        extra={
          <>
            <SearchInput
              placeholder="Search vendors"
              onSearch={(value) => setSearchValue(value)}
              onChange={(value) => setSearchValue(value)}
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() => form$.current?.open({})}
              className="ant-select-selection-item-remove"
            >
              Add vendor
            </Button>
          </>
        }
      />

      <div className="overflow-auto px-5 pb-5">
        <VendorsList searchValue={searchValue} />
      </div>

      <VendorDrawer ref={form$} />
    </div>
  );
});

import { Select } from 'antd';
import cx from 'classnames';
import { useGetSystemDomains } from '../../../../hooks/k8s/system';
import { useMemo } from 'react';

function getDomainsData(domains = []) {
  const list = [];
  for (let record of domains) {
    if (Array.isArray(record.subdomains)) {
      const options = [{ label: record.domain, value: record.domain }];
      for (let subdomain of record.subdomains) {
        const result = subdomain + '.' + record.domain;
        options.push({ value: result, label: result });
      }
      list.push({ label: record.domain, options });
    } else {
      list.push(record.domain);
    }
  }

  return list;
}

export function DomainSelect({ value, onChange, className, size = 'large', placeholder }) {
  const domains = useGetSystemDomains();
  const list = useMemo(() => {
    if (!domains.data) {
      return [];
    }

    return getDomainsData(domains.data);
  }, [domains.data]);

  return (
    <Select
      defaultValue={value}
      options={list}
      showSearch
      placeholder={placeholder}
      className={cx('focus:text-black w-full', className)}
      value={value}
      onChange={onChange}
    />
  );
}

import {
  AlignCenterOutlined,
  BarsOutlined,
  CodeOutlined,
  CodeSandboxOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Tabs, message } from 'antd';
import {
  useDeleteDeployedRelease,
  useGetDeployedRelease,
} from '../../../hooks/deployments/deployments';
import { useNavigate, useParams } from 'react-router-dom';

import { APP_SCOPES } from '../../../constants/services';
import { Activities } from '../../../containers/Activities';
import { DeploymentInfo } from '../containers/DeploymentInfo';
import { DeploymentStatus } from '../containers/DeploymentStatus';
import { DeploymentSummary } from '../components/DeploymentSummary';
import { PageHeader } from '@ant-design/pro-layout';
import { PodLogs } from '../containers/PodLogs';
import { Scripts } from '../containers/Scripts';
import { ValuesForm } from '../containers/ValuesForm';
import { useGetReleaseActivities } from '../../../hooks/activity';
import { useRef } from 'react';
import { withPermissions } from '../../../context/permissions';

export const DeploymentDrillDown = withPermissions(
  [APP_SCOPES.RELEASES],
  function DeploymentDrillDown() {
    const drawer$ = useRef();
    const navigate = useNavigate();
    const { id } = useParams();

    const release$ = useGetDeployedRelease(id);
    const delete$ = useDeleteDeployedRelease({
      onSuccess: () => {
        navigate(`/deployments`);
        message.success('Deployment deleted successfully!');
      },
    });

    const onDelete = () =>
      Modal.confirm({
        okText: 'Yes',
        type: 'warning',
        okType: 'danger',
        cancelText: 'No',
        title: 'Delete deployment?',
        onOk: () => delete$.mutateAsync(id),
        content: 'This deployment will be deleted with all resources! Are you sure?',
      });

    const onBack = () => navigate(`/deployments`);

    const onUpdateDeployment = () => drawer$.current?.open();

    const activities$ = useGetReleaseActivities(release$.data?.id, {
      enabled: !!release$.data?.id,
    });

    return (
      <div className="bg-white min-h-full shadow-md overflow-y-auto">
        <PageHeader
          onBack={onBack}
          title={release$.data?.name}
          subTitle={release$.data?.description}
          className="mb-5 border-b-1 border-gray-100"
          extra={
            <Button onClick={onDelete} danger ghost>
              Delete
            </Button>
          }
        />
        <div className="px-5 pb-5 z-10">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: (
                  <span>
                    <AlignCenterOutlined />
                    Summary
                  </span>
                ),
                key: 'summary',
                children: (
                  <Row gutter={16}>
                    <Col span={16}>
                      <DeploymentSummary
                        release={release$.data || {}}
                        isFetched={release$.isFetched}
                        isLoading={release$.isLoading}
                        onUpdateDeployment={onUpdateDeployment}
                      />
                    </Col>
                    <Col span={8}>
                      <DeploymentStatus release={id} />
                    </Col>
                  </Row>
                ),
              },
              {
                label: (
                  <span>
                    <CodeOutlined />
                    Release Info
                  </span>
                ),
                key: 'release-info',
                children: <DeploymentInfo release={release$.data || {}} />,
              },
              {
                label: (
                  <span>
                    <CodeSandboxOutlined />
                    Logs
                  </span>
                ),
                key: 'logs',
                children: <PodLogs release={release$.data || {}} />,
              },
              {
                label: (
                  <span>
                    <SettingOutlined />
                    Scripts
                  </span>
                ),
                key: 'scripts',
                children: <Scripts isLoading={release$.isLoading} release={release$.data || {}} />,
              },
              {
                label: (
                  <span>
                    <BarsOutlined />
                    Activities
                  </span>
                ),
                key: 'activities',
                children: <Activities title={false} query$={activities$} />,
              },
            ]}
          />
        </div>

        <ValuesForm.Drawer release={release$.data || {}} ref={drawer$} />
      </div>
    );
  },
);

import { useRef, useState } from 'react';
import {
  useCreateApplicationForVendor,
  useDeleteApp,
  useGetVendorApplications,
} from '../../../hooks/vendors/vendors-management';
import { Button, Drawer, List, message, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApplicationSummary } from '../components/ApplicationSummary';
import { NewApplicationForm } from '../components/NewApplicationForm';
import useInvalidateVendorActivityQueries from '../hooks/useInvalidateVendorActivityQueries';
import { StatusComponent } from '../components/StatusComponent';
import SearchInput from '../../../components/SearchInput';
import { useIsCan } from '../../../context/permissions';
import { APP_SCOPES } from '../../../constants/services';

export const VendorApplicationsContainer = ({ vendor = {} }) => {
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const can = useIsCan();

  const vendorApps = useGetVendorApplications(
    vendor?.id,
    { search: searchValue, limit: 5, page: currentPage },
    { enabled: !!vendor?.id },
  );

  const invalidateVendorActivityQueries = useInvalidateVendorActivityQueries();

  const createApplication = useCreateApplicationForVendor({
    onSuccess: () => {
      vendorApps.refetch();
      message.success('App added');
      setNewApplicationModalVisible(false);
      invalidateVendorActivityQueries();
    },
    onError: (error) => {
      if (error?.response?.data?.message.includes('duplicate')) {
        message.error('Application identifier is already in use');
      } else {
        message.error(error?.response?.data?.message || 'An error occurred');
      }
    },
  });

  const removeApplication = useDeleteApp({
    onSuccess: () => {
      vendorApps.refetch();
      message.success('App deleted');
      invalidateVendorActivityQueries();
    },
  });

  const onDeleteApplication = (applicationId) => {
    Modal.confirm({
      title: `Are you sure you want to delete this application?`,
      okButtonProps: { danger: true },
      onOk: () => removeApplication.mutateAsync({ applicationId }),
      content: `The app will be permanently deleted.`,
    });
  };

  const newApplicationForm = useRef();
  const [newApplicationModalVisible, setNewApplicationModalVisible] = useState(false);
  const [applicationSummaryDrawer, setApplicationSummaryDrawer] = useState(false);

  const onAddNewApplication = (vendorId) =>
    newApplicationForm.current
      ?.validateFields()
      .then((values) => createApplication.mutateAsync({ vendorId: vendorId, payload: values }))
      .catch((e) => {
        if (e.errorFields?.length > 0) return;
      });

  return (
    <>
      <List
        className="mb-10"
        pagination={{
          pageSize: 5,
          onChange: (page) => setCurrentPage(page),
          total: vendorApps.data?.meta?.totalItems,
        }}
        bordered
        header={
          <div className="flex flex-row">
            <h2 className="text-lg flex-grow">Vendor applications</h2>
            <Button.Group>
              <div className="mr-2">
                <SearchInput
                  placeholder="Search applications"
                  onSearch={(value) => setSearchValue(value)}
                  onChange={(value) => setSearchValue(value)}
                />
              </div>

              <Button
                type="primary"
                onClick={() => setNewApplicationModalVisible(true)}
                className="inline-flex items-center"
              >
                <PlusOutlined className="text-xs" />
                Add application
              </Button>
            </Button.Group>
          </div>
        }
        loading={vendorApps.isLoading}
        dataSource={vendorApps.data?.items || []}
        renderItem={(vendorApp) => (
          <List.Item
            onClick={() => {
              setSelectedApplication(vendorApp);
              setApplicationSummaryDrawer(true);
            }}
            actions={[
              <Button
                disabled={!can([APP_SCOPES['DELETE_VENDORS']])}
                key="remove"
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteApplication(vendorApp.id);
                }}
                danger
              >
                Remove
              </Button>,
            ]}
            className="px-3 cursor-pointer hover:bg-gray-50"
          >
            <>
              <List.Item.Meta
                title={`${vendorApp.name}`}
                description={`identifier: ${vendorApp.identifier}`}
              />
              <StatusComponent status={vendorApp.status} />
            </>
          </List.Item>
        )}
      />

      <Drawer
        width="95%"
        destroyOnClose
        open={applicationSummaryDrawer}
        onClose={() => {
          setApplicationSummaryDrawer(false);
          setSelectedApplication(null);
        }}
        title={`Manage ${selectedApplication?.name} for ${vendor?.name}`}
      >
        <ApplicationSummary applicationId={selectedApplication?.id} />
      </Drawer>

      <Modal
        confirmLoading={createApplication.isLoading}
        closable
        open={newApplicationModalVisible}
        width={'80%'}
        onCancel={() => {
          setNewApplicationModalVisible(false);
        }}
        onOk={() => onAddNewApplication(vendor?.id)}
        okText={'Create'}
        destroyOnClose
        title={'Create application'}
      >
        <NewApplicationForm ref={newApplicationForm} />
      </Modal>
    </>
  );
};

import * as Yaml from 'yaml';

import { Button, Drawer, Form, Spin, notification } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  useGetInspectChart,
  useGetInspectRelease,
  useUpdateDeployedRelease,
} from '../../../hooks/deployments/deployments';

import Editor from '@monaco-editor/react';
import _ from 'lodash';

function merge(expected = {}, current = {}) {
  return _.keys(expected).reduce((obj, key) => {
    if (_.isObject(current[key])) {
      return { ...obj, [key]: merge(expected[key], current[key]) };
    } else {
      return { ...obj, [key]: current[key] };
    }
  }, {});
}

export const ValuesForm = forwardRef(function ValuesForm({ release, onFinish }, ref) {
  const form$ = useRef();
  const chart$ = useGetInspectChart(release.chart);
  const release$ = useGetInspectRelease(release.id);

  const current = Yaml.parse(release$.data?.data || '');
  const expected = Yaml.parse(chart$.data?.values || '');

  useImperativeHandle(ref, () => form$.current);

  useEffect(() => {
    const release = merge(expected, current);
    form$.current?.setFieldsValue({ values: Yaml.stringify(release) });
  }, [current, expected]);

  if (chart$.isLoading || release$.isLoading) {
    return <Spin />;
  }

  return (
    <Form layout="vertical" ref={form$} onFinish={onFinish}>
      <Form.Item name="values" label="Values" required rules={[{ required: true }]}>
        <Editor height="550px" className="h-full pb-1" theme="vs-dark" language="yaml" />
      </Form.Item>
    </Form>
  );
});

ValuesForm.Drawer = forwardRef(function ValuesFormDrawer({ release }, ref) {
  const form$ = useRef();
  const [visible, setVisible] = useState(false);

  const update$ = useUpdateDeployedRelease({
    onSuccess: () => {
      close();
      notification.success({
        message: 'Running update',
        description: 'New resources will be deployed soon...',
      });
    },
    onError: (e) => {
      notification.success({ message: 'Update failed', description: JSON.stringify(e) });
    },
  });

  const open = () => setVisible(true);
  const close = () => setVisible(false);

  useImperativeHandle(ref, () => ({ close, open }));

  const onUpdateRelease = ({ values }) => update$.mutateAsync({ release: release.id, values });

  return (
    <Drawer
      width="50%"
      destroyOnClose
      onClose={close}
      visible={visible}
      title="Update values"
      footer={
        <div className="mb-0 text-right">
          <Button disabled={update$.isLoading} htmlType="button" onClick={close} className="mr-2">
            Close
          </Button>
          <Button
            disabled={update$.isLoading}
            type="primary"
            htmlType="submit"
            onClick={() => form$.current?.submit()}
          >
            Deploy
          </Button>
        </div>
      }
    >
      <ValuesForm onFinish={onUpdateRelease} release={release} ref={form$} />
    </Drawer>
  );
});

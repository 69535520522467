import { Form, Input, Modal } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { DomainSelect } from './DomainSelect';
import { kebabCase } from 'lodash';
import { useUpdateOrganizationInfo } from '../../../../hooks/organizations/organizations-management';

export const OrganizationInfoForm = forwardRef(function OrganizationInfoForm(
  { size = 'large', onFinish, organization = {} },
  ref,
) {
  const form = useRef();
  const [domain, setDomain] = useState();

  const host = useMemo(() => {
    const [subdomain, ...rest] = (organization.subdomain || '').split('.');
    return { subdomain, hostname: rest.join('.') };
  }, [organization.subdomain]);

  useEffect(() => {
    if (!domain && host.hostname) {
      setDomain(host.hostname);
    }
  }, [domain, host]);

  useImperativeHandle(ref, () => form.current);

  const onValuesChange = (updates) => {
    if (updates.name) {
      form.current?.setFieldsValue({ subdomain: kebabCase(updates.name) });
    }
  };

  const onSubmit = (values) => {
    if (!domain) {
      return;
    }

    return onFinish({ ...values, domain, id: organization.id });
  };

  return (
    <Form onFinish={onSubmit} ref={form} layout="vertical" onValuesChange={onValuesChange}>
      <Form.Item
        initialValue={organization.name}
        label="Organization name"
        name="name"
        required
        rules={[{ required: true }, { max: 32, min: 2 }]}
      >
        <Input placeholder="e.g. CSI Helsinki" />
      </Form.Item>
      <Form.Item
        initialValue={host.subdomain}
        dependencies={['name']}
        name="subdomain"
        required
        rules={[
          { required: true },
          { min: 5, max: 32 },
          {
            validator: (rule, value, cb) => (!domain ? cb('Select domain') : cb()),
          },
        ]}
      >
        <Input
          className="domain-input"
          placeholder="my-organization"
          addonAfter={
            <DomainSelect
              value={domain || host.hostname}
              onChange={setDomain}
              placeholder="example.com"
            />
          }
        />
      </Form.Item>
      <Form.Item
        initialValue={organization.description}
        required
        rules={[{ required: true }]}
        label="Description"
        name="description"
        className="mb-1"
      >
        <Input.TextArea placeholder="Few words about this organization..." />
      </Form.Item>
    </Form>
  );
});

OrganizationInfoForm.Modal = forwardRef(function OrganizationInfoFormModal(props, ref) {
  const form$ = useRef();
  const [visible, setVisible] = useState();
  const update$ = useUpdateOrganizationInfo();

  const open = () => setVisible(true);
  const close = () => setVisible(false);

  useImperativeHandle(ref, () => ({ open, close }));

  const onFinish = ({ domain, subdomain, ...values }) => {
    close();
    const hostname = `${subdomain}.${domain}`;

    return update$.mutate({
      ...values,
      subdomain: hostname,
    });
  };

  return (
    <Modal visible={visible} onCancel={close} onOk={() => form$.current?.submit()}>
      <OrganizationInfoForm ref={form$} {...props} onFinish={onFinish} />
    </Modal>
  );
});

import { AutoComplete, Button, Col, Drawer, Form, Input, Row, Select, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  useDeployRelease,
  useGetDeploymentFilters,
  useGetInspectChart,
} from '../../../hooks/deployments/deployments';

import Editor from '@monaco-editor/react';
import { useGetCharts } from '../../../hooks/k8s/system';

export const DeploymentForm = forwardRef(function DeploymentForm(
  { size = 'large', onDeployed },
  ref,
) {
  const form$ = useRef();
  const [chart, setChart] = useState('');
  const filters$ = useGetDeploymentFilters();
  const [visible, setVisible] = useState(false);

  useGetInspectChart(chart, {
    enabled: !!chart,
    onSuccess: ({ values }) => {
      form$.current?.setFieldsValue({ values });
    },
  });

  const deploy$ = useDeployRelease({
    onSuccess: () => {
      onDeployed();
      setVisible(false);
      notification.success({
        message: 'Deployment started!',
      });
    },
    onError: (err) => {
      notification.error({
        message: 'Deployment error',
        description: JSON.stringify(err),
      });
    },
  });

  const charts$ = useGetCharts();
  const versions = useMemo(() => {
    if (!charts$.data || !chart) {
      return [];
    }
    const [repo, name] = chart.split('/');
    const repository = charts$.data?.find(({ repository }) => repository === repo);
    const record = (repository.charts || []).find((item) => item.name === name);

    return record?.versions || [];
  }, [chart, charts$.data]);

  const open = () => setVisible(true);
  const close = () => setVisible(false);

  useImperativeHandle(ref, () => ({ open, close }));

  const onFormSubmit = (values) => deploy$.mutate({ ...values, chart });

  return (
    <Drawer
      width="50%"
      destroyOnClose
      onClose={close}
      visible={visible}
      title="New deployment"
      footer={
        <div className="mb-0 text-right">
          <Button disabled={deploy$.isLoading} htmlType="button" onClick={close} className="mr-2">
            Close
          </Button>
          <Button
            disabled={deploy$.isLoading}
            type="primary"
            htmlType="submit"
            onClick={() => form$.current?.submit()}
          >
            Deploy
          </Button>
        </div>
      }
    >
      <Form ref={form$} layout="vertical" onFinish={onFormSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              validateTrigger={['onBlur', 'onSubmit']}
              name="name"
              label="Name"
              required
              rules={[{ required: true }, { min: 5, max: 32 }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              validateTrigger={['onBlur', 'onSubmit']}
              name="description"
              label="Description"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              validateTrigger={['onBlur', 'onSubmit']}
              name="ownerName"
              label="Owner"
              required
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              validateTrigger={['onBlur', 'onSubmit']}
              name="ownerEmail"
              label="Owner email"
              required
              rules={[{ required: true }, { type: 'email' }]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              validateTrigger={['onBlur', 'onSubmit']}
              name="namespace"
              label="Namespace"
              required
              rules={[{ required: true }, { min: 5, max: 32 }]}
            >
              <AutoComplete
                showSearch
                options={(filters$.data?.namespaces || []).map((value) => ({ value }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              validateTrigger={['onBlur', 'onSubmit']}
              label="Chart"
              name="chart"
              required
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                value={chart}
                onChange={(value) => setChart(value)}
                loading={charts$.isLoading}
              >
                {(charts$.data || []).map((repo) => (
                  <Select.OptGroup label={repo.repository}>
                    {repo.charts?.map((chart) => (
                      <Select.Option value={`${repo.repository}/${chart.name}`}>
                        {`${repo.repository}/${chart.name}`}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Chart version"
              name="chartVersion"
              required
              rules={[{ required: true }]}
            >
              <Select showSearch defaultActiveFirstOption>
                {versions.map(({ version }) => (
                  <Select.Option value={version} key={version}>
                    {version}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          className="mb-2"
          validateTrigger={['onBlur', 'onSubmit']}
          required
          rules={[{ required: true }]}
          name="values"
          label="Values"
        >
          <Editor height="950px" className="h-full pb-1" theme="vs-dark" language="yaml" />
        </Form.Item>
      </Form>
    </Drawer>
  );
});

import cx from 'classnames';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';

export const DomainForm = forwardRef(function DomainForm({ size = 'large', onSubmit }, ref) {
  const form$ = useRef();
  const [blocked, setBlocked] = useState(false);
  const [visible, setVisible] = useState(false);

  const reset = () => {
    setBlocked(false);
    form$.current?.resetFields();
  };

  const set = (values) => {
    form$.current?.setFieldsValue(values);
    if (values.id) {
      setBlocked(true);
    }

    if (values.subdomains.length > 0 || values.tags.length > 0) {
      setVisible(true);
    }
  };

  useImperativeHandle(ref, () => ({ form: form$, set, reset }));

  const onReset = (e) => {
    e.stopPropagation();

    setBlocked(false);
    form$.current?.resetFields();
  };

  return (
    <Form ref={form$} layout="vertical" onFinish={onSubmit}>
      <Form.Item hidden name="id" initialValue={undefined} />
      <Form.Item
        name="domain"
        initialValue={''}
        className="mb-2"
        label="Zone name"
        rules={[{ required: true }]}
        required
      >
        <Input
          disabled={blocked}
          placeholder="example.com"
          addonAfter={
            <>
              <button type="submit">Submit</button>
            </>
          }
        />
      </Form.Item>
      <fieldset>
        <div
          className=" flex flex-row items-center cursor-pointer mb-2"
          onClick={() => setVisible(!visible)}
        >
          <InfoCircleOutlined className="cursor-pointer mr-2 text-blue-500" />
          <span className="text-sm flex-grow">Optional info</span>
          <div className="inline-flex items-center">
            {blocked ? (
              <button
                type="button"
                onClick={onReset}
                className="text-sm underline text-blue-500 mr-2"
              >
                Reset form
              </button>
            ) : null}
            {visible ? (
              <UpOutlined className="text-xs cursor-pointer" />
            ) : (
              <DownOutlined className="text-xs cursor-pointer" />
            )}
          </div>
        </div>
        <Row gutter={16} className={cx({ hidden: !visible })}>
          <Col span={12}>
            <Form.Item label="Subdomains" name="subdomains" initialValue={[]}>
              <Select mode="tags" placeholder="dev, test, staging" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tags" name="tags" initialValue={[]}>
              <Select mode="tags" placeholder="main zone" />
            </Form.Item>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
});

import { List } from 'antd';
import moment from 'moment';
import { forwardRef, useImperativeHandle } from 'react';

import { useGetSystemEnvs } from '../../../hooks/k8s/envs';

export const EnvsTable = forwardRef(function EnvsTable({ onSelect }, ref) {
  const envs$ = useGetSystemEnvs();

  useImperativeHandle(ref, () => envs$);

  const onSelectItem = (item) => onSelect(item);

  return (
    <List
      bordered
      rowKey="id"
      loading={envs$.isLoading}
      dataSource={envs$.data || []}
      renderItem={(item) => (
        <List.Item onClick={() => onSelectItem(item)}>
          <div className="cursor-pointer w-full">
            <p className="mb-2">{item.name}</p>
            <div className="text-xs text-gray-500 w-full items-center justify-between flex flex-row">
              <span className="">{item.description || 'No description provided...'}</span>
              <span>{moment(item.updatedAt).format('L LT')}</span>
            </div>
          </div>
        </List.Item>
      )}
    />
  );
});

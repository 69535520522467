import { Card, List, Skeleton, Tag } from 'antd';
import { useGetVendorsList } from '../../../hooks/vendors/vendors-management';
import { useNavigate } from 'react-router-dom';
import { StatusComponent } from '../components/StatusComponent';
import { useState } from 'react';

export const VendorsList = ({ searchValue }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const list$ = useGetVendorsList({
    search: searchValue,
    page: currentPage,
    limit: 10,
    relations: ['applications'],
  });

  const navigate = useNavigate();

  return (
    <>
      {list$.isLoading && <Skeleton active />}
      <List
        rowKey="id"
        pagination={{
          pageSize: 10,
          total: list$.data?.meta?.totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        split={false}
        loading={list$.isLoading}
        dataSource={list$.data?.items || []}
        renderItem={(item) => (
          <List.Item
            onClick={() =>
              navigate(`/vendors/${item.id}`, {
                id: item.id,
              })
            }
            className="flex flex-row"
          >
            <Card className="flex-grow cursor-pointer border-gray-300 hover:bg-gray-50">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <Card.Meta title={item.name} description={item.description} />

                  <div className="ml-10">
                    <Card.Meta
                      title={
                        <span className="text-xs">{`Apps: ${item.applications?.length}`}</span>
                      }
                    />
                  </div>
                </div>
                <StatusComponent status={item.status} />
              </div>
              <div className="mt-3">
                <Card.Meta
                  title={item?.tags.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                />
              </div>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};

import { Input } from 'antd';

const SearchInput = ({ placeholder, onSearch, onChange }) => {
  return (
    <Input.Search
      onChange={(e) => {
        if (!e.target.value) {
          onChange('');
        }
      }}
      placeholder={placeholder}
      enterButton
      allowClear
      onSearch={onSearch}
    />
  );
};

export default SearchInput;

import { useEffect, useState } from 'react';
import {
  useGetActiveUsers,
  useGetLoggedUsers,
  useGetOrganizationUsers,
} from '../../../../hooks/organizations/organizations-management';
import { Table, Layout, Input, notification, Button, Divider, Tag } from 'antd';
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
} from 'recharts';
import * as moment from 'moment';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { withPermissions } from '../../../../context/permissions';
import { APP_SCOPES } from '../../../../constants/services';

const onboardingStatusTexts = {
  'not-onboarded': 'Not onboarded',
  onboarded: 'Onboarded',
  invited: 'Invited',
};

export const OrganizationUsers = withPermissions(
  [APP_SCOPES.SENSITIVEDATA],

  function OrganizationUsers({ organizationId }) {
    const { data, isLoading, isFetching } = useGetOrganizationUsers(organizationId, {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: (e) => {
        notification.error({ message: 'Fetching users failed' });
      },
    });
    const {
      data: activeUsers,
      isLoading: isLoadingActiveUsers,
      isFetching: isFetchingActiveUsers,
      refetch: refetchActiveUsers,
    } = useGetActiveUsers(organizationId, {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: (e) => {
        notification.error({ message: 'Fetching active users failed' });
      },
    });

    const {
      data: loggedUsers,
      isLoading: isLoadingLoggedUsers,
      isFetching: isFetchingLoggedUsers,
      refetch: refetchLoggedUsers,
    } = useGetLoggedUsers(organizationId, {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: (e) => {
        notification.error({ message: 'Fetching logged users failed' });
      },
    });

    const isLoadingChartData =
      isLoadingLoggedUsers ||
      isFetchingLoggedUsers ||
      isLoadingActiveUsers ||
      isFetchingActiveUsers;

    const [organizationUsers, setOrganizationUsers] = useState(data);
    const [chartData, setChartData] = useState(undefined);

    useEffect(() => {
      setOrganizationUsers(data);
    }, [data]);

    useEffect(() => {
      if (activeUsers && loggedUsers) {
        setChartData(createChartData(loggedUsers, activeUsers));
      }
    }, [activeUsers, loggedUsers]);

    const [focusActiveUsersBar, setFocusActiveUsersBar] = useState(null);
    const [focusLoggedUsersBar, setFocusLoggedUsersBar] = useState(null);

    return (
      <>
        <div className="flex justify-center">
          {chartData && (
            <p className="text-md text-lg font-semibold">
              Total users in organization: {organizationUsers?.length}
            </p>
          )}
        </div>
        <div className="flex justify-center">
          <ResponsiveContainer width="100%" aspect={3}>
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  refetchActiveUsers();
                  refetchLoggedUsers();
                }}
                className="flex flex-row items-center mr-1"
                disabled={isLoadingChartData}
              >
                {isLoadingChartData ? <LoadingOutlined /> : <ReloadOutlined />}
                Refresh Chart
              </Button>
            </div>
            {chartData && (
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} fill="#fbfbfb" />
                <Tooltip cursor={false} />
                <Bar
                  onMouseEnter={() => setFocusLoggedUsersBar(false)}
                  onMouseLeave={() => setFocusLoggedUsersBar(true)}
                  fillOpacity={!focusLoggedUsersBar ? 0.8 : 1}
                  fill={'#80ccda'}
                  type="linear"
                  dataKey="loggedUsersAmount"
                  name="Logged users"
                />
                <Bar
                  onMouseEnter={() => setFocusActiveUsersBar(false)}
                  onMouseLeave={() => setFocusActiveUsersBar(true)}
                  fillOpacity={!focusActiveUsersBar ? 0.8 : 1}
                  fill="#1A9475"
                  type="linear"
                  dataKey="activeUsersAmount"
                  name="Active users"
                />
                <Legend />
                <XAxis dataKey="date" />
                <YAxis allowDecimals={false} />
              </BarChart>
            )}
          </ResponsiveContainer>
        </div>
        <Divider />

        <div className="flex flex-row items-center p-5">
          <p className="flex-grow mr-2 text-md font-semibold">Organization users</p>
          <Input
            placeholder="Search for users..."
            onChange={(e) => {
              const currValue = e.target.value;
              const filteredData = data.filter((entry) => {
                return (
                  entry.fullName.toLowerCase().includes(currValue.toLowerCase()) ||
                  entry.email?.toLowerCase().includes(currValue.toLowerCase())
                );
              });

              setOrganizationUsers(filteredData);
            }}
            className="w-96"
          />
        </div>

        <Layout.Content className="px-5">
          <Table
            bordered
            pagination={{
              total: organizationUsers?.length,
              pageSize: 15,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            rowClassName="cursor-pointer"
            loading={isLoading || isFetching}
            dataSource={organizationUsers}
            columns={[
              {
                title: 'User type',
                dataIndex: 'userType',
                width: 100,
                sorter: (a, b) => a.userType.localeCompare(b.userType),
                render: (userType) => {
                  switch (userType) {
                    case 'admin':
                      return <Tag className="bg-green-200">{userType.toUpperCase()}</Tag>;
                    case 'normal':
                      return <Tag className="bg-blue-200">{userType.toUpperCase()}</Tag>;
                    default:
                      return <Tag className="bg-red-200">{userType.toUpperCase()}</Tag>;
                  }
                },
              },
              {
                title: 'Full name',
                dataIndex: 'fullName',
                sorter: (a, b) => a.fullName.localeCompare(b.fullName),
              },
              {
                title: 'Status',
                dataIndex: 'status',
                render: (status) =>
                  status === 'active' ? (
                    <Tag className="bg-green-200">ACTIVE</Tag>
                  ) : (
                    <Tag className="bg-red-200">INACTIVE</Tag>
                  ),
              },
              { title: 'Email', dataIndex: 'email', render: (email) => email ?? '-' },

              {
                title: 'Onboarding status',
                dataIndex: 'onboardingStatus',
                render: (onboardingStatus) => onboardingStatusTexts[onboardingStatus],
                sorter: (a, b) => a.onboardingStatus.localeCompare(b.onboardingStatus),
              },

              {
                title: 'Last active on',
                dataIndex: 'lastActiveOn',
                render: (lastActiveOn) =>
                  lastActiveOn ? moment(lastActiveOn).format('DD.MM.YYYY') : '-',
                sorter: (a, b) => a.lastActiveOn.localeCompare(b.lastActiveOn),
              },
              {
                title: 'Last logged on',
                dataIndex: 'lastLoggedOn',
                render: (lastLoggedOn) =>
                  lastLoggedOn ? moment(lastLoggedOn).format('DD.MM.YYYY') : '-',
                sorter: (a, b) => a.lastLoggedOn.localeCompare(b.lastLoggedOn),
              },
            ]}
          />
        </Layout.Content>
      </>
    );
  },
  false,
  true,
);

function createChartData(organizationLoggedUsers, organizationActiveUsers) {
  const dataArray = [];

  const last12Months = getMonthStartDates();

  last12Months.forEach((date) => {
    const loggedUsersAmount = organizationLoggedUsers?.find(
      (x) => x.monthYear === moment(date).format('M/YY'),
    );

    const activeUsersAmount = organizationActiveUsers?.find(
      (x) => x.monthYear === moment(date).format('M/YY'),
    );

    dataArray.push({
      date: moment(date).format('M/YY'),
      loggedUsersAmount: loggedUsersAmount?.loggedUsers ?? 0,
      activeUsersAmount: activeUsersAmount?.activeUsers ?? 0,
    });
  });

  return dataArray;
}

function getMonthStartDates() {
  const monthDates = [];
  let currentMonthIteration = moment().month();
  let currentYearIteration = moment().year();
  for (let i = 0; i < 12; i++) {
    monthDates.push(
      moment()
        .month(currentMonthIteration)
        .year(currentYearIteration)
        .startOf('month')
        .toISOString(),
    );

    const subtractOneMonth = moment()
      .month(currentMonthIteration)
      .year(currentYearIteration)
      .subtract(1, 'months');
    currentMonthIteration = subtractOneMonth.month();
    currentYearIteration = subtractOneMonth.year();
  }
  return monthDates.reverse();
}

import { message, Modal } from 'antd';
import { forwardRef } from 'react';
import { useAddVendor, useDeleteVendor } from '../../../hooks/vendors/vendors-management';
import { VendorForm } from '../components/VendorForm';
import { useQueryClient } from 'react-query';

export const VendorDrawer = forwardRef(function VendorDrawer(_, form$) {
  const queryClient = useQueryClient();
  const deleteVendor = useDeleteVendor({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      form$.current?.close();
      message.success('Vendor deleted');
    },
  });

  const addVendor = useAddVendor({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      form$.current?.close();
      message.success('Vendor added');
    },
    onError: (error) => {
      if (error?.response?.data?.message.includes('duplicate')) {
        message.error('Vendor identifier is already in use');
      } else {
        message.error(error?.response?.data?.message || 'An error occurred');
      }
    },
  });

  const onDelete = ({ id }) => {
    Modal.confirm({
      title: `Are you sure you want to delete this vendor?`,
      okButtonProps: { danger: true },
      onOk: () => deleteVendor.mutate(id),
      content: `Vendor will be permanently deleted.`,
    });
  };

  const onFormSubmit = (values) => {
    return addVendor.mutate(values);
  };

  return <VendorForm.Drawer ref={form$} onDelete={onDelete} onFinish={onFormSubmit} />;
});

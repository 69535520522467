import { Button, Descriptions, Skeleton } from 'antd';
import { HighlightOutlined, LinkOutlined, MailOutlined, RocketOutlined } from '@ant-design/icons';
import { capitalize, startCase } from 'lodash';

import { OrganizationDeploymentStatus } from './OrganizationDeploymentStatus';
import moment from 'moment';
import { useGetInspectRelease } from '../../../../hooks/deployments/deployments';
import { useGetOrganizationDeploymentValues } from '../../../../hooks/organizations/organizations-management';
import { useMemo } from 'react';
import { useIsCan } from '../../../../context/permissions';
import { APP_SCOPES } from '../../../../constants/services';

export function OrganizationSummary({
  onEdit,
  isLoading,
  isFetched,
  organization = {},
  isError,
  onManageEnv,
  onUpdateDeployment,
}) {
  const domain = organization.subdomain;

  const values$ = useGetOrganizationDeploymentValues(organization.id, {
    enabled: !!organization.id,
  });

  const externalDbInfo = useMemo(() => {
    if (values$.data?.data?.externalDatabase?.enabled) {
      return values$.data?.data?.externalDatabase;
    }

    return null;
  }, [values$.data]);

  return (
    <>
      {isLoading && <Skeleton />}
      {isFetched && (
        <Descriptions
          title="General information"
          layout="vertical"
          bordered
          extra={[
            <Button onClick={onEdit} className="inline-flex items-center">
              <HighlightOutlined />
              Edit
            </Button>,
          ]}
        >
          <Descriptions.Item label="Owner">{organization.ownerName}</Descriptions.Item>
          <Descriptions.Item label="Organization name">{organization.name}</Descriptions.Item>
          <Descriptions.Item label="Created at">
            {moment(organization.createdAt).format('L LT')}
          </Descriptions.Item>
          <Descriptions.Item label="Contact email">
            <a
              className="inline-flex items-center text-blue-500"
              referrerPolicy="no-referrer"
              target="_blank"
              href={`mailto:${organization.ownerEmail}`}
              rel="noreferrer"
            >
              <MailOutlined className="mr-2" />
              {organization.ownerEmail}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Language">
            {organization.organizationLanguage}
          </Descriptions.Item>
          <Descriptions.Item label="Home country">{organization.homeCountry}</Descriptions.Item>
          <Descriptions.Item label="Description">{organization.description}</Descriptions.Item>
        </Descriptions>
      )}
      <br />
      {isLoading && <Skeleton />}
      {isFetched && (
        <Descriptions
          title={'Deployment information'}
          layout="vertical"
          bordered
          extra={[
            <Button onClick={() => onUpdateDeployment(true)} className="inline-flex items-center">
              <HighlightOutlined />
              Change version
            </Button>,
            organization.env ? (
              <Button
                onClick={() => onManageEnv(organization.env)}
                className="ml-3 inline-flex items-center"
              >
                <RocketOutlined />
                Manage env variables
              </Button>
            ) : null,
          ]}
        >
          <Descriptions.Item label="Status">
            <span className="capitalize inline-flex items-center">
              <OrganizationDeploymentStatus className="mx-2" organization={organization} />
              {startCase(organization.status)}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Chart">{organization.chart}</Descriptions.Item>
          <Descriptions.Item label="Domain">
            <a
              className="inline-flex items-center text-blue-500"
              referrerPolicy="no-referrer"
              target="_blank"
              href={`//${domain}`}
              rel="noreferrer"
            >
              <LinkOutlined className="mr-2" />
              {domain}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Namespace">{organization.namespace}</Descriptions.Item>
          <Descriptions.Item label="Db mode">{capitalize(organization.dbMode)}</Descriptions.Item>
          <Descriptions.Item label="Db type">{capitalize(organization.dbType)}</Descriptions.Item>
          <Descriptions.Item label="Own db">{JSON.stringify(!!externalDbInfo)}</Descriptions.Item>
          {externalDbInfo ? (
            <>
              <Descriptions.Item label="Database host">{externalDbInfo.host}</Descriptions.Item>
              <Descriptions.Item label="Database user">{externalDbInfo.user}</Descriptions.Item>
              <Descriptions.Item label="Database name">{externalDbInfo.database}</Descriptions.Item>
            </>
          ) : null}
          <Descriptions.Item label="Docker image">
            {organization.image}:{organization.appVersion}
          </Descriptions.Item>
        </Descriptions>
      )}
      <br />
    </>
  );
}

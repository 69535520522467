import { useAuth0 } from '@auth0/auth0-react';
import { Button, Result, Spin } from 'antd';
import { difference } from 'lodash';
import React, { createContext, useContext } from 'react';

import { useGetUsersPermissions } from '../hooks/auth';

const PermissionsContext = createContext([]);

export const useIsCan = () => {
  const permissions = useContext(PermissionsContext);
  return (scopes = []) => !difference(scopes, permissions).length;
};

export const usePermissions = () => useContext(PermissionsContext);

export const useHasPermissions = (scopes) => {
  const permissions = useContext(PermissionsContext);

  return !difference(scopes, permissions).length;
};

export function PermissionsProvider({ children }) {
  const permissions$ = useGetUsersPermissions();

  if (permissions$.isLoading) {
    return <Spin />;
  }

  return (
    <PermissionsContext.Provider value={permissions$.data}>{children}</PermissionsContext.Provider>
  );
}

export function IfUserCan({ scopes = [], children, fallback = null }) {
  const isPermitted = useHasPermissions(scopes);

  if (!isPermitted) {
    return fallback;
  }

  return <>{children}</>;
}

export const withPermissions =
  (scopes = [], Component, fallback, hideLogout) =>
  ({ children, ...props }) => {
    const { logout } = useAuth0();
    const isPermitted = useHasPermissions(scopes);

    if (!isPermitted) {
      return (
        fallback || (
          <div className="h-screen flex flex-row items-center justify-center">
            <Result
              status={403}
              title="Forbidden"
              subTitle="You have no access rights to this page."
              extra={
                hideLogout ? null : (
                  <Button
                    onClick={() =>
                      logout({
                        logoutParams: {
                          returnTo: window.location.origin,
                        },
                      })
                    }
                  >
                    Sign out
                  </Button>
                )
              }
            />
          </div>
        )
      );
    }

    return <Component {...props}>{children}</Component>;
  };

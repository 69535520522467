import ReactJson from 'react-json-view';
import { Spin } from 'antd';
import { useGetOrganizationDeploymentValues } from '../../../../hooks/organizations/organizations-management';
import { withPermissions } from '../../../../context/permissions';
import { APP_SCOPES } from '../../../../constants/services';

export const OrganizationDeploymentInfo = withPermissions(
  [APP_SCOPES.SENSITIVEDATA],

  function OrganizationDeploymentInfo({ organization = {} }) {
    const values$ = useGetOrganizationDeploymentValues(organization.id, {
      enabled: !!organization.id,
    });
    return (
      <Spin spinning={values$.isLoading || false}>
        <ReactJson theme="twilight" src={values$.data?.data || {}} style={{ minHeight: '300px' }} />
      </Spin>
    );
  },
  false,
  true,
);

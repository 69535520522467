import { List } from 'antd';
import { CommonActivityView } from '../components/activity-fills/CommonActivityView';

export function Activities({ query$, title = 'Activity' }) {
  return (
    <div>
      {title ? <p className="flex-grow mr-2 text-md font-semibold">{title}</p> : null}
      <List
        split={false}
        bordered={false}
        pagination={false}
        dataSource={query$.data || []}
        renderItem={(activity) => (
          <List.Item className="w-full">
            <CommonActivityView {...activity} />
          </List.Item>
        )}
      />
    </div>
  );
}

import { useMutation, useQuery } from 'react-query';
import { SERVICE_KEYS } from '../../constants/services';
import { useService } from '../../context/services';

export function useAddVendor(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation((payload) => service.addVendor(payload), options);
}

export function useCreateApplicationForVendor(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation(
    ({ vendorId, payload }) => service.createApplication(vendorId, payload),
    options,
  );
}

export function useUpdateAppForVendor(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation(
    ({ applicationId, payload }) => service.updateApplication(applicationId, payload),
    options,
  );
}

export function useDeleteApp(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation(({ applicationId }) => service.deleteApplication(applicationId), options);
}

export function useUpdateVendor(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);

  return useMutation(({ vendorId, payload }) => service.updateVendor(vendorId, payload), options);
}

export function useDeleteVendor(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation((id) => service.deleteVendor(id), options);
}

export function useGetVendorsList(filters = {}, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useQuery(['vendors', filters], () => service.getVendors(filters), options);
}

export function useGetVendor(vendorId, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useQuery(['vendors', vendorId], () => service.getVendorById(vendorId), options);
}

export function useGetVendorApplications(vendorId, filters = {}, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useQuery(
    ['vendorsapps', vendorId, filters],
    () => service.vendorApplications(vendorId, filters),
    options,
  );
}

export function useGetApplication(applicationId, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useQuery(
    ['vendorapplication', applicationId],
    () => service.getApplication(applicationId),
    options,
  );
}

export function useGetApplicationOrganizations(applicationId, filters = {}, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useQuery(
    ['applicationOrganizations', applicationId, filters],
    () => service.applicationOrganizations(applicationId, filters),
    options,
  );
}

export function useGetAvailableOrganizations(applicationId, filters = {}, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useQuery(
    ['availableOrganizations', applicationId, filters],
    () => service.getAvailableOrganizations(applicationId, filters),
    options,
  );
}

export function useLinkOrganizationToApplication(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);

  return useMutation(
    ({ applicationId, organizationIds }) =>
      service.linkOrganization(applicationId, organizationIds),
    options,
  );
}

export function useUnlinkOrganizationFromApplication(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation(
    ({ applicationId, organizationId }) =>
      service.unLinkOrganization(applicationId, organizationId),
    options,
  );
}

export function useGetKeysForVendor(vendorId, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);

  return useQuery(['vendor.keys', vendorId], () => service.getVendorKeys(vendorId), options);
}

export function useGetKeysForApplication(applicationId, options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);

  return useQuery(
    ['application.keys', applicationId],
    () => service.getApplicationKeys(applicationId),
    options,
  );
}

export function useRemoveVendorKey(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation(({ keyId }) => service.removeVendorKey(keyId), options);
}

export function useAddKey(options = {}) {
  const service = useService(SERVICE_KEYS.VENDORS);
  return useMutation(
    ({ vendorId, applicationId, validity }) => service.addKey(vendorId, applicationId, validity),
    options,
  );
}

import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Drawer, Form, Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export function EnvToJson() {
  const [json, setJson] = useState('');

  const onEnvChange = (value = '') => {
    const lines = value.split('\n');
    const pairs = lines.filter((line) => !!line && line[0] !== '#').map((line) => line.split('='));
    const json = pairs.reduce((acc, [key, ...values]) => ({ ...acc, [key]: values.join('') }), {});

    return setJson(JSON.stringify(json, null, 2));
  };

  const onCopy = () => {
    message.success('Copied!');
    navigator.clipboard.writeText(json);
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Env file">
        <Input.TextArea
          style={{ minHeight: '250px' }}
          onChange={(e) => onEnvChange(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className="form-item--json-output"
        label={
          <div className="flex flex-row items-center w-full">
            <span className="flex-grow">JSON output</span>{' '}
            <Button onClick={onCopy} className="inline-flex items-center">
              <CopyOutlined /> Copy
            </Button>
          </div>
        }
      >
        <Input.TextArea value={json} style={{ minHeight: '250px' }} />
      </Form.Item>
    </Form>
  );
}

EnvToJson.Drawer = forwardRef(function EnvToJsonDrawer(props, ref) {
  const [visible, setVisible] = useState(false);

  const open = () => setVisible(true);
  const close = () => setVisible(false);

  useImperativeHandle(ref, () => ({ open, close }));

  return (
    <Drawer
      title="Convert dotenv to json"
      width={650}
      destroyOnClose
      visible={visible}
      onClose={close}
      footer={null}
      closable
    >
      <EnvToJson />
    </Drawer>
  );
});

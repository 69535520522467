import { Layout, Spin } from 'antd';

import { ApplicationHeader } from './containers/ApplicationHeader';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import moment from 'moment';

export const AppLayout = () => (
  <>
    <ApplicationHeader />
    <Layout className="h-screen">
      <Layout.Content className="h-full px-5 py-2 overflow-y-auto">
        <Suspense fallback={<Spin />}>
          <Outlet />
        </Suspense>
      </Layout.Content>
    </Layout>
    <Layout.Footer style={{ textAlign: 'center' }}>
      © {moment().year()} Copyright CSI Helsinki Oy
    </Layout.Footer>
  </>
);

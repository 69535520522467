import cx from 'classnames';
import { useRef, useState } from 'react';
import { Button, List, message, Modal } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';

import {
  useAddRepository,
  useGetRepositories,
  useSyncAllRepositories,
} from '../../../hooks/k8s/repositories';
import { RepositoryForm } from '../components/RepositoryForm';

export function Repositories() {
  const form = useRef();
  const [modal, setModal] = useState(false);

  const repositories$ = useGetRepositories({});
  const sync$ = useSyncAllRepositories({
    onSuccess: () => {
      message.success('Index files loaded!');
    },
  });

  const add$ = useAddRepository({
    onError: () => {
      repositories$.refetch();
      message.error('Cannot add repository');
    },
    onSuccess: () => {
      setModal(false);
      repositories$.refetch();
      return message.success('Repository added!');
    },
  });

  const onAddRepository = () =>
    form.current?.validateFields().then((values) => add$.mutateAsync(values));

  return (
    <>
      <List
        bordered
        header={
          <div className="flex flex-row">
            <h2 className="text-lg flex-grow">Helm repositories</h2>
            <Button.Group>
              <Button onClick={() => sync$.mutateAsync()} className="inline-flex items-center">
                <SyncOutlined
                  className={cx('text-xs animate-none', {
                    'animate-spin': sync$.isLoading,
                    'animate-none': !sync$.isLoading,
                  })}
                />
                Sync
              </Button>
              <Button onClick={() => setModal(true)} className="inline-flex items-center">
                <PlusOutlined className="text-xs" />
                Add repository
              </Button>
            </Button.Group>
          </div>
        }
        loading={repositories$.isLoading}
        dataSource={repositories$.data?.repositories}
        renderItem={(repository) => (
          <List.Item>
            <List.Item.Meta title={repository.name} description={repository.url} />
          </List.Item>
        )}
      />

      <Modal
        confirmLoading={add$.isLoading}
        closable
        visible={modal}
        onCancel={() => setModal(false)}
        onOk={onAddRepository}
        okText="Add repo"
        destroyOnClose
        title="Add helm repo"
      >
        <RepositoryForm ref={form} />
      </Modal>
    </>
  );
}

import { Alert, Button, Modal, Steps, message, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { APP_SCOPES } from '../../../constants/services';
import { CloseCircleOutlined } from '@ant-design/icons';
import { NewOrganizationDatabaseInfo } from '../components/organization/new/NewOrganizationDatabaseInfo';
import { NewOrganizationDeploymentInfo } from '../components/organization/new/NewOrganizationDeploymentInfo';
import { NewOrganizationGeneralInfo } from '../components/organization/new/NewOrganizationGeneralInfo';
import { NewOrganizationSummary } from '../components/organization/new/NewOrganizationSummary';
import { PageHeader } from '@ant-design/pro-layout';
import { useAddOrganization } from '../../../hooks/organizations/organizations-management';
import { useState } from 'react';
import { withPermissions } from '../../../context/permissions';

export const OrganizationNew = withPermissions(
  [APP_SCOPES.ORGANIZATIONS],
  function OrganizationNew() {
    const navigate = useNavigate();
    const { app } = useParams();

    const [currentStep, setCurrentStep] = useState(0);
    const [nextStepEnabled, setNextStepEnabled] = useState(false);
    const [deploymentError, setDeploymentError] = useState(null);
    const [deploymentInProgress, setDeploymentInProgress] = useState(false);

    const [organizationData, setOrganizationData] = useState({
      general: { schema: app },
      deployment: {},
      database: {
        useOwnDb: false,
        dbMode: 'new',
        dbType: 'postgres',
      },
    });

    const deployOrganization = useAddOrganization({
      onSuccess: () => {
        navigate('/organizations');
        return message.success('Organization created!');
      },
      onError: () => {
        return message.error('Can not create organization!');
      },
    });

    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
      setCurrentStep(currentStep - 1);
    };

    const deployStep = async () => {
      try {
        setDeploymentInProgress(true);
        await NewOrganizationDeploymentInfo.validator({}, organizationData.deployment);
        const newOrganizationPayload = {
          ...organizationData.general,
          ...organizationData.deployment,
          ...organizationData.database,
        };
        return deployOrganization.mutate(newOrganizationPayload);
      } catch (err) {
        setDeploymentError(err);
        setDeploymentInProgress(false);
      }
    };
    const steps = [
      {
        title: 'General information',
        content: (
          <div style={{ margin: '20px' }}>
            <NewOrganizationGeneralInfo
              organizationData={organizationData}
              setOrganizationData={setOrganizationData}
              setNextStepEnabled={setNextStepEnabled}
            />
          </div>
        ),
      },
      {
        title: 'Deployment',
        content: (
          <div style={{ margin: '20px' }}>
            <NewOrganizationDeploymentInfo
              organizationData={organizationData}
              setOrganizationData={setOrganizationData}
              setNextStepEnabled={setNextStepEnabled}
            />
          </div>
        ),
      },
      {
        title: 'Database',
        content: (
          <div style={{ margin: '20px' }}>
            <NewOrganizationDatabaseInfo
              organizationData={organizationData}
              setOrganizationData={setOrganizationData}
              setNextStepEnabled={setNextStepEnabled}
            />
          </div>
        ),
      },
      {
        title: 'Summary',
        content: (
          <div style={{ margin: '20px' }}>
            <NewOrganizationSummary organizationData={organizationData} />
          </div>
        ),
      },
    ];

    console.debug('OrgInfo', organizationData);
    const onCancel = () =>
      Modal.confirm({
        type: 'warning',
        cancelText: 'Close form',
        title: 'Close this form?',
        okText: 'Continue editing',
        cancelButtonProps: { danger: true },
        okButtonProps: { type: 'default' },
        onCancel: () => navigate('/organizations'),
        content: 'After closing all data will be cleared from this form.',
      });

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    return (
      <>
        {deploymentError ? (
          <Alert
            type="error"
            description={deploymentError?.message || deploymentError}
            className="mb-3"
          />
        ) : null}
        <div className="min-h-full shadow-md overflow-y-auto">
          <PageHeader
            extra={
              <CloseCircleOutlined
                onClick={onCancel}
                className="text-xl text-gray-400 cursor-pointer"
              />
            }
          />
          <div className="mx-auto w-2/3" style={{ maxWidth: '1200px' }}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Typography.Title>New organization</Typography.Title>
            </div>
            <Steps current={currentStep} items={items} />
            <div>{steps[currentStep].content}</div>
            <div
              style={{
                marginTop: 24,
                marginBottom: 50,
              }}
            >
              {currentStep > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prevStep()}
                >
                  Previous
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button type="primary" disabled={!nextStepEnabled} onClick={() => nextStep()}>
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button type="primary" onClick={() => deployStep()} loading={deploymentInProgress}>
                  Deploy
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);

import { useRef, useState } from 'react';
import { Modal, Descriptions, Button, Divider, Skeleton, message, Checkbox } from 'antd';

import { Editor } from '@monaco-editor/react';
import { ConnectedOrganizationsContainer } from '../containers/ConnectedOrganizationsContainer';
import { CheckOutlined, CloseCircleOutlined, HighlightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ApplicationKeysContainer } from '../containers/ApplicationKeysContainer';
import {
  useGetApplication,
  useUpdateAppForVendor,
} from '../../../hooks/vendors/vendors-management';
import { NewApplicationForm } from './NewApplicationForm';
import useInvalidateVendorActivityQueries from '../hooks/useInvalidateVendorActivityQueries';
import { useQueryClient } from 'react-query';
import { StatusComponent } from './StatusComponent';
import { useIsCan } from '../../../context/permissions';
import { APP_SCOPES } from '../../../constants/services';

export const ApplicationSummary = ({ applicationId }) => {
  const application = useGetApplication(applicationId);
  const can = useIsCan();

  const [updateApplicationModalVisible, setUpdateApplicationModalVisible] = useState(false);

  const invalidateVendorActivityQueries = useInvalidateVendorActivityQueries();
  const queryClient = useQueryClient();

  const updateApplication = useUpdateAppForVendor({
    onSuccess: () => {
      message.success('App updated');
      setUpdateApplicationModalVisible(false);
      invalidateVendorActivityQueries();
      queryClient.invalidateQueries({ queryKey: ['vendorsapps'] });
      application.refetch();
    },
    onError: (error) => {
      if (error?.response?.data?.message.includes('duplicate')) {
        message.error('Application identifier is already in use');
      } else {
        message.error(error?.response?.data?.message || 'An error occured');
      }
    },
  });

  const newApplicationForm = useRef();

  const onUpdateApplication = async (applicationId) => {
    newApplicationForm.current
      ?.validateFields()
      .then((values) => {
        return updateApplication.mutateAsync({ applicationId: applicationId, payload: values });
      })
      .catch((e) => {
        if (e.errorFields?.length > 0) return;
      });
  };

  const updateStatus = (status) => {
    return updateApplication.mutateAsync({
      applicationId: application.data.id,
      payload: { status },
    });
  };

  const title = () => {
    return (
      <div>
        {application.data.name} <StatusComponent status={application.data.status} />
      </div>
    );
  };

  return (
    <>
      {application.isLoading && <Skeleton />}
      {application.data && (
        <>
          <Descriptions
            title={title()}
            layout="vertical"
            bordered
            extra={[
              <Button
                disabled={!can([APP_SCOPES['DELETE_VENDORS']])}
                key={'status'}
                onClick={() =>
                  updateStatus(application.data.status === 'active' ? 'inactive' : 'active')
                }
                danger={application.data.status === 'active'}
                type="primary"
                className={'inline-flex items-center mr-2 '}
              >
                {application.data.status === 'active' ? <CloseCircleOutlined /> : <CheckOutlined />}
                {application.data.status === 'active' ? 'Deactivate' : 'Activate'}
              </Button>,
              <Button
                key={'edit'}
                onClick={() => setUpdateApplicationModalVisible(true)}
                className="inline-flex items-center"
              >
                <HighlightOutlined />
                Edit
              </Button>,
            ]}
          >
            <Descriptions.Item label="Identifier">{application?.data.identifier}</Descriptions.Item>

            <Descriptions.Item label="Created at">
              {moment(application.data.createdAt).format('L LT')}
            </Descriptions.Item>

            <Descriptions.Item label="Preinstall for new organizations">
              <div className="flex flex-row ">
                <p>Preinstall</p>
                <Checkbox className="ml-3" checked={application.data.preinstalled} />
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Description">
              {application.data.description}
            </Descriptions.Item>
          </Descriptions>

          <>
            <Divider />

            <p className="pb-5 font-bold text-medium">Allowed API endpoints for application:</p>
            <Editor
              options={{
                readOnly: true,
              }}
              height={'350px'}
              theme="vs-dark"
              value={application?.data.allowedEndpoints}
              defaultValue={application?.data.allowedEndpoints}
            />

            <Divider />

            <div>
              <p className="font-bold text-medium pb-5">Application keys: </p>
              <ApplicationKeysContainer application={application.data} />
            </div>

            <Divider />
            <>
              <p className="font-bold text-medium">Connected organizations: </p>
              <ConnectedOrganizationsContainer application={application.data} />
            </>
          </>
        </>
      )}

      <Modal
        confirmLoading={updateApplication.isLoading}
        closable
        open={updateApplicationModalVisible}
        width={'80%'}
        onCancel={() => {
          setUpdateApplicationModalVisible(false);
        }}
        onOk={() => onUpdateApplication(application?.data.id)}
        okText={'Save'}
        destroyOnClose
        title={'Edit application'}
      >
        <NewApplicationForm application={application.data} ref={newApplicationForm} />
      </Modal>
    </>
  );
};

import {
  AlignCenterOutlined,
  BarsOutlined,
  CodeOutlined,
  CodeSandboxOutlined,
  SettingOutlined,
  SlidersOutlined,
  UserOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Tabs, message, Collapse } from 'antd';
import {
  useDeleteOrganization,
  useGetOrganizationInfo,
  useUpdateOrganizationDeployment,
  useUpdateOrganizationEnvs,
} from '../../../hooks/organizations/organizations-management';
import { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { APP_SCOPES } from '../../../constants/services';
import { Activities } from '../../../containers/Activities';
import { AppScaling } from '../components/organization/AppScaling';
import { ChangeVersionForm } from '../components/organization/ChangeVersionForm';
import { EnvEditor } from '../../system/containers/EnvEditor';
import { OrganizationBackups } from '../components/organization/OrganizationBackups';
import { OrganizationDeploymentInfo } from '../components/organization/OrganizationDeploymentInfo';
import { OrganizationDeploymentStatus } from '../components/organization/OrganizationDeploymentStatus';
import { OrganizationHealthStatus } from '../components/organization/OrganizationHealthStatus';
import { OrganizationInfoForm } from '../components/organization/OrganizationInfoForm';
import { OrganizationPodLogs } from '../components/organization/OrganizationPodLogs';
import { OrganizationUsers } from '../components/organization/OrganizationUsers';
import { OrganizationReleaseStatus } from '../components/organization/OrganizationReleaseStatus';
import { OrganizationScripts } from '../components/organization/OrganizationScripts';
import { OrganizationSummary } from '../components/organization/OrganizationSummary';
import { PageHeader } from '@ant-design/pro-layout';
import { useGetOrganizationActivities } from '../../../hooks/activity';
import { useIsCan, withPermissions } from '../../../context/permissions';

export const OrganizationDrilldown = withPermissions(
  [APP_SCOPES.ORGANIZATIONS],
  function OrganizationDrilldown() {
    const envs$ = useRef();
    const info$ = useRef();
    const deploymentForm = useRef();

    const [deploymentError, setDeploymentError] = useState({
      isVisible: false,
      error: null,
    });

    const can = useIsCan();

    const [deploymentFormView, setDeploymentFormView] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();

    const organization = useGetOrganizationInfo(id);
    console.debug('organization', organization);
    const isError = useMemo(
      () => organization.isLoading || organization.data?.status === 'error',
      [organization.data?.status, organization.isLoading],
    );

    const redeployOrganization = useUpdateOrganizationDeployment({
      onSuccess: () => {
        setDeploymentFormView(false);
        return message.success('Organization updating...');
      },
      onError: (err) => {
        setDeploymentFormView(false);
        setDeploymentError({ isVisible: true, error: err });
      },
    });

    const setEnvs$ = useUpdateOrganizationEnvs({
      onSuccess: () => {
        envs$.current?.close();
        return message.success('Organization updating...');
      },
      onError: () => {
        return message.error('Can not update organization!');
      },
    });

    const onBack = () => navigate('/organizations');
    const remove$ = useDeleteOrganization({
      onSuccess: () => {
        message.success('Organization deleted!');
        return navigate('/organizations');
      },
    });

    const onDelete = () =>
      Modal.confirm({
        okText: 'Yes',
        type: 'warning',
        okType: 'danger',
        cancelText: 'No',
        title: 'Delete organization',
        content: 'This organization will be deleted with all resources! Are you sure?',
        onOk: () => remove$.mutate({ organization: id, cleanup: true }),
      });

    const onRedeployOrganization = () => {
      return deploymentForm.current
        ?.validateFields()
        .then((values) => redeployOrganization.mutateAsync(values));
    };

    const onUpdateOrgEnvs = ({ id, values }) => {
      const payload = { env: id, values, organization: id };
      return setEnvs$.mutate(payload);
    };

    const activityHistory = useGetOrganizationActivities(organization.data?.id, {
      enabled: !!organization.data?.id,
    });

    return (
      <div className="bg-white min-h-full shadow-md overflow-y-auto flex flex-col">
        <Modal
          title={
            <span>
              Organization version update failed <CloseCircleOutlined style={{ color: 'red' }} />
            </span>
          }
          open={deploymentError.isVisible}
          footer={null}
          onCancel={() => setDeploymentError({ isVisible: false, error: '' })}
        >
          <Collapse
            items={[
              {
                key: '1',
                label: `${deploymentError.error?.message}`,
                children: <p>{deploymentError.error?.response?.data?.message}</p>,
              },
            ]}
          />
        </Modal>
        <PageHeader
          onBack={onBack}
          title={organization.data?.name}
          subTitle={
            <div className="flex flex-row items-center">
              {organization.data?.description}
              <OrganizationDeploymentStatus className="mx-3" organization={organization.data} />
            </div>
          }
          className="mb-5 border-b-1 border-gray-100"
          extra={
            <Button
              disabled={!can([APP_SCOPES['DELETE_ORGANIZATIONS']])}
              loading={remove$.isLoading}
              onClick={onDelete}
              danger
              ghost
            >
              Delete
            </Button>
          }
        />
        <div className="px-5 pb-5 z-10">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                icon: <AlignCenterOutlined />,
                label: <span>Summary</span>,
                key: 'summary',
                children: (
                  <Row gutter={16}>
                    <Col span={16}>
                      <OrganizationSummary
                        isError={isError}
                        organization={organization.data}
                        isLoading={organization.isLoading}
                        isFetched={organization.isFetched}
                        onEdit={() => info$.current?.open()}
                        onManageEnv={(env) => envs$.current.open(env)}
                        onUpdateDeployment={() => setDeploymentFormView(true)}
                      />
                    </Col>
                    <Col span={8}>
                      <OrganizationHealthStatus organizationId={id} />
                      <br />
                      <OrganizationReleaseStatus organization={id} />
                      <br />
                      <OrganizationBackups organization={id} />
                      <br />
                    </Col>
                  </Row>
                ),
              },
              {
                icon: <UserOutlined />,
                label: <span>Users</span>,
                key: 'organization-users',
                children: <OrganizationUsers organizationId={organization.data?.id} />,
              },
              {
                icon: <CodeOutlined />,
                label: <span>Release Info</span>,
                key: 'release-info',
                children: <OrganizationDeploymentInfo organization={organization.data} />,
              },
              {
                icon: <CodeSandboxOutlined />,
                label: <span>Logs</span>,
                key: 'logs',
                children: <OrganizationPodLogs organization={organization.data} />,
              },
              {
                icon: <SlidersOutlined />,
                label: <span>Resources</span>,
                key: 'resources',
                children: <AppScaling organization={organization.data} />,
              },
              {
                icon: <SettingOutlined />,
                label: <span>Scripts</span>,
                key: 'scripts',
                children: (
                  <OrganizationScripts
                    isLoading={organization.isLoading}
                    organization={organization.data}
                  />
                ),
              },
              {
                icon: <BarsOutlined />,
                label: <span>Activities</span>,
                key: 'activities',
                children: <Activities title={false} query$={activityHistory} />,
              },
            ]}
          />
        </div>

        <Modal
          destroyOnClose
          confirmLoading={redeployOrganization.isLoading}
          onOk={onRedeployOrganization}
          visible={deploymentFormView}
          title="Update app version"
          onCancel={() => setDeploymentFormView(false)}
        >
          <ChangeVersionForm organization={organization.data} ref={deploymentForm} />
        </Modal>

        <OrganizationInfoForm.Modal organization={organization.data} ref={info$} />

        <EnvEditor ref={envs$} mode="organization" onUpdateOrgEnvs={onUpdateOrgEnvs} />
      </div>
    );
  },
);

import { useQueryClient } from 'react-query';

const useInvalidateVendorActivityQueries = () => {
  const queryClient = useQueryClient();

  const invalidateVendorActivityQueries = () => {
    queryClient.invalidateQueries({ queryKey: 'activity.vendor' });
  };

  return invalidateVendorActivityQueries;
};

export default useInvalidateVendorActivityQueries;

export class ActivityService {
  constructor(api) {
    this.api = api;
  }

  getOrganizationActivities(id) {
    return this.api.get(`/activity/organization/${id}`).then(({ data }) => data);
  }

  getVendorActivities(id) {
    return this.api.get(`/activity/vendor/${id}`).then(({ data }) => data);
  }

  getReleaseActivities(id) {
    return this.api.get(`/activity/release/${id}`).then(({ data }) => data);
  }

  getEnvActivities(id) {
    return this.api.get(`/activity/env/${id}`).then(({ data }) => data);
  }

  getSystemActivities() {
    return this.api.get(`/activity/system`).then(({ data }) => data);
  }
}

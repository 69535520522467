export class SystemService {
  constructor(api) {
    this.api = api;
  }

  getUserPermissions() {
    return this.api.get('auth/permissions').then(({ data }) => data);
  }

  getConnectionStatus() {
    return this.api.get('system/connection').then(({ data }) => data);
  }

  getSystemInfo() {
    return this.api.get('system/info').then(({ data }) => data);
  }

  getCharts() {
    return this.api.get('system/charts').then(({ data }) => data.data);
  }

  updateSystem(payload) {
    return this.api.post('system/update', payload).then(({ data }) => data);
  }

  getSystemPods() {
    return this.api.get('system/pods').then(({ data }) => data);
  }

  // Domains management
  getSystemDomains() {
    return this.api.get('domains').then(({ data }) => data);
  }

  createSystemDomain(data) {
    return this.api.post('domains', data).then(({ data }) => data);
  }

  updateSystemDomain({ id, ...data }) {
    return this.api.patch(`domains/${id}`, data).then(({ data }) => data);
  }

  deleteSystemDomain(data) {
    return this.api.delete(`domains`, { data }).then(({ data }) => data);
  }
}

import { useMutation, useQuery } from 'react-query';

import { SERVICE_KEYS } from '../../constants/services';
import { useService } from '../../context/services';

export function useGetSystemConnection(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useQuery('system.connection', () => service.getConnectionStatus(), {
    ...options,
    retry: false,
  });
}

export function useUpdateSystem(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useMutation((payload) => service.updateSystem(payload), options);
}

export function useGetCharts(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useQuery(['system.charts'], () => service.getCharts(), options);
}

export function useGetSystemInfo(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useQuery('system.info', () => service.getSystemInfo(), { ...options, retry: false });
}

export function useGetSystemPods(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useQuery('system.pods', () => service.getSystemPods(), { ...options, retry: false });
}

export function useGetSystemDomains(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useQuery('system.domains', () => service.getSystemDomains(), { ...options });
}

export function useInspectChart(chart, options = {}) {
  const service = useService(SERVICE_KEYS.K8S);
  return useQuery(['system.inspect-chart', chart], () => service.inspectChart(chart), {
    ...options,
  });
}

export function useCreateSystemDomain(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useMutation((payload) => service.createSystemDomain(payload), options);
}

export function useUpdateSystemDomain(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useMutation((payload) => service.updateSystemDomain(payload), options);
}

export function useDeleteSystemDomain(options = {}) {
  const service = useService(SERVICE_KEYS.SYSTEM);
  return useMutation((payload) => service.deleteSystemDomain(payload), options);
}

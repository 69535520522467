import { Button, Card, Collapse, List, Tooltip } from 'antd';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  WarningOutlined,
  DesktopOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import {
  useGetOrganizationHealthStatus,
  useGetOrganizationInfo,
} from '../../../../hooks/organizations/organizations-management';
import * as moment from 'moment';

function StatusItem({ ok = true }) {
  if (ok) {
    return <CheckCircleOutlined className="text-green-600" />;
  }

  return <CloseCircleOutlined className="text-red-600" />;
}

function HealthItem({ icon, title, statusText = null, itemStatus = null }) {
  return (
    <>
      <List.Item className="px-4 hover:bg-gray-50 cursor-pointer">
        <List.Item.Meta
          className="flex flex-row justify-center"
          description={
            <p className="flex flex-row items-center mb-0 text-gray-800">
              {icon ? <span className="mr-3 flex">{icon}</span> : null}
              {title}
            </p>
          }
        />
        {statusText ? <span className="text-xs">{statusText}</span> : itemStatus}
      </List.Item>
    </>
  );
}

const renderVersionIcon = (organizationAppVersion, azureVersion) => {
  const strippedAzureVersion = azureVersion?.split('csi-lawyer-app:')[1];
  const versionInDatabase = organizationAppVersion;

  if (strippedAzureVersion && versionInDatabase) {
    if (strippedAzureVersion !== versionInDatabase) {
      return (
        <Tooltip title="Deployed API version does not match with the version configuration in Azure">
          <WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      );
    } else {
      return <CheckCircleOutlined className="text-green-600" />;
    }
  }

  return <QuestionOutlined style={{ color: 'red' }} />;
};

export function OrganizationHealthStatus({ organizationId }) {
  const health$ = useGetOrganizationHealthStatus(organizationId);
  const organization = useGetOrganizationInfo(organizationId);

  return (
    <Card
      className="text-xs border-gray-300"
      title="Health"
      bodyStyle={{ padding: 20 }}
      loading={health$.isLoading || health$.isRefetching}
      extra={
        <Button
          onClick={() => {
            health$.refetch();
            organization.refetch();
          }}
        >
          Refresh
        </Button>
      }
    >
      <List>
        <HealthItem
          icon={<GlobalOutlined />}
          title="Api status"
          itemStatus={<StatusItem ok={health$.data?.info?.application?.status === 'ok'} />}
        />
        <HealthItem
          icon={<DatabaseOutlined />}
          title="Database connection"
          itemStatus={<StatusItem ok={health$.data?.info?.application?.status === 'ok'} />}
        />
        <HealthItem
          icon={<CalendarOutlined />}
          title="Api started"
          statusText={
            health$.data?.info?.application?.startServerTime
              ? moment(health$.data?.info?.application?.startServerTime).fromNow()
              : 'Unknown'
          }
        />

        <HealthItem
          icon={<DesktopOutlined />}
          itemStatus={renderVersionIcon(
            organization.data?.appVersion,
            health$.data?.info?.application.appVersion,
          )}
          title={`Deployed version: ${organization.data?.appVersion}`}
        />
        <HealthItem
          icon={<CalendarOutlined />}
          title={`Version updated `}
          statusText={`${moment(organization?.data?.organizationVersionUpdatedOn).fromNow()}`}
        />
      </List>

      <Collapse
        items={[
          {
            key: '1',
            label: `Organization Health JSON `,
            children: (
              <pre className="bg-gray-100 p-3">
                <code>{JSON.stringify(health$.data, null, 2)}</code>
              </pre>
            ),
          },
        ]}
      />
    </Card>
  );
}

import { Button, Card, List, Modal, Spin, Tooltip, message } from 'antd';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import {
  useCreateOrganizationBackup,
  useGetOrganizationBackups,
  useRestoreOrganizationBackup,
} from '../../../../hooks/organizations/organizations-management';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { find } from 'lodash';
import moment from 'moment';

function BackupCard({ backup, onRestore, isProcessingRestore }) {
  const actions = useCallback(() => {
    switch (backup.status) {
      case 'RESTORING':
      case 'RUNNING':
        return (
          <Tooltip title="Running...">
            <Spin />
          </Tooltip>
        );
      case 'COMPLETED':
        return (
          <Tooltip title="Creating backup">
            <Button
              disabled={isProcessingRestore}
              loading={isProcessingRestore}
              onClick={() => {
                onRestore(backup.id);
              }}
            >
              Restore
            </Button>
          </Tooltip>
        );
      case 'RESTORED':
        return <CheckOutlined className="text-green-400" />;
      case 'ERROR':
        return (
          <Tooltip title="Backup creation error">
            <WarningOutlined className="text-red-500" />
          </Tooltip>
        );
      default:
        return null;
    }
  }, [backup, onRestore, isProcessingRestore]);

  return (
    <div className="px-3 flex flex-row items-center w-full cursor-pointe">
      <div className="flex-grow flex-1">
        <p className="mb-1">{moment(backup.createdAt).format('Do MMM YYYY HH:mm')}</p>
        <p className="text-xs text-gray-500">{backup.key}</p>
        <p className="text-xs text-gray-500">{backup.id}</p>
        <p className="text-xs text-gray-500">{backup.status}</p>
      </div>
      <div>{actions()}</div>
    </div>
  );
}

export function OrganizationBackups({ organization }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const list$ = useGetOrganizationBackups(organization, {
    refetchInterval: isProcessing ? 1000 : false,
  });

  useEffect(() => {
    setIsProcessing(!!find(list$.data, (item) => ['RUNNING', 'RESTORING'].includes(item.status)));
  }, [list$.data]);

  const create$ = useCreateOrganizationBackup({
    onError: (err) => {
      message.error({
        content: `Backup creation failed with error: ${err?.response?.data?.message}`,
        duration: 10,
        key: 'creationerror',
        onClick: () => message.destroy('creationerror'),
      });
      list$.refetch();
    },
    onSuccess: () => {
      list$.refetch();
      message.success('Backup successfully created!');
    },
  });

  const restore$ = useRestoreOrganizationBackup({
    onError: () => message.error('Cannot restore backup'),
    onSuccess: () => {
      list$.refetch();
      message.success('Restore successful');
    },
  });

  const onCreateBackup = (e) => {
    message.info('Backup creation started...');
    return create$.mutate(organization);
  };
  const onRestore = (backup) => {
    Modal.confirm({
      title: `Are you absolutely sure?`,
      okButtonProps: { danger: true },
      onOk: () => {
        message.info('Database restore initiated... ');
        return restore$.mutate({ organization, backup });
      },
      content: `The database will be restored to the selected backup.`,
    });
  };

  const extra = useMemo(() => {
    return (
      <>
        <Button
          loading={create$.isLoading}
          disabled={create$.isLoading || restore$.isLoading}
          onClick={onCreateBackup}
          type="primary"
        >
          Create backup
        </Button>
      </>
    );
  }, [onCreateBackup, restore$.isLoading]);

  if (organization.dbType === 'mssql') {
    return (
      <Card title="Backups" className="border-gray-300">
        <Card.Meta description="MsSQL backups not supported yet." />
      </Card>
    );
  }

  return (
    <Card
      loading={list$.isLoading}
      title="DB Backups"
      extra={extra}
      bodyStyle={{ padding: 0 }}
      className="border-gray-300"
    >
      <List
        rowKey="id"
        pagination={false}
        dataSource={list$.data || []}
        renderItem={(item, index) => (
          <List.Item key={index} className="hover:bg-gray-50 cursor-pointer">
            <BackupCard
              isProcessingRestore={restore$.isLoading}
              backup={item}
              onRestore={onRestore}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

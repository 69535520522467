import { forwardRef, useEffect } from 'react';
import { Input, Form, Checkbox } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Editor } from '@monaco-editor/react';
import { useForm } from 'antd/es/form/Form';

export const NewApplicationForm = forwardRef(function NewApplicationForm({ application }, ref) {
  const [form] = useForm();

  useEffect(() => {
    if (application?.id) return;
    form.setFieldsValue({
      identifier: (+new Date() * Math.random()).toString(36).substring(0, 5),
    });
  }, [form, application?.id]);

  return (
    <Form form={form} ref={ref} layout="vertical">
      <Form.Item
        initialValue={application?.name}
        label="Name"
        name="name"
        className="font-bold"
        required
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        initialValue={application?.identifier}
        label="Identifier"
        name="identifier"
        className="font-bold"
        tooltip="Auto generated identifier, can be edited to be up to 15 characters"
        required
        rules={[
          { required: true, message: 'Identifier is required' },
          { max: 15, message: 'Identifier cannot be longer than 15 characters' },
          { min: 5, message: 'Identifier must be at least 5 characters' },
          {
            pattern: /^[a-z0-9]*$/,
            message: 'Identifier cannot contain capital letters',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        initialValue={application?.preinstalled}
        className="mb-5"
        name="preinstalled"
        valuePropName="checked"
      >
        <Checkbox>Preinstall for new organizations</Checkbox>
      </Form.Item>
      <Form.Item initialValue={application?.description} name="description" label="Description">
        <TextArea value={application?.description} />
      </Form.Item>
      <Form.Item
        name="allowedEndpoints"
        label="Allowed API endpoints for application"
        tooltip="
        
        JSON object with allowed endpoints and methods, leave this empty to allow all endpoints.
        You can also use wildcards to allow child endpoints

        Example: 
        [
          {
            '/api/v1/endpoint': 'POST,PUT,GET,DELETE' -- regular endpoint
            '/api/v1/endpoint/*': 'POST,PUT,GET,DELETE' -- wildcard endpoint (eg. get by ID)
            '/api/v1/endpoint/**': 'POST,PUT,GET,DELETE' -- double wildcard endpoint (eg. all childendpoints allowed)
          }
        ]
        
        "
        initialValue={
          application?.allowedEndpoints?.length || application?.id
            ? application?.allowedEndpoints
            : `[{ "/api/v1/endpoint": "POST,PUT,GET,DELETE" }]`
        }
      >
        <Editor height={'350px'} className="h-full pb-1" theme="vs-dark" defaultLanguage="json" />
      </Form.Item>
    </Form>
  );
});

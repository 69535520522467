import { Card, Modal, message } from 'antd';
import { useGetSystemInfo, useUpdateSystem } from '../../../hooks/k8s/system';
import { useRef, useState } from 'react';

import { SystemUpdateForm } from './SystemUpdateForm';

export function SysInfo() {
  const form = useRef();
  const [modal, setModal] = useState(false);

  const info$ = useGetSystemInfo({});
  const update$ = useUpdateSystem({
    onSuccess: () => {
      setModal(false);
      message.success('Updating admin dashboard....');
    },
    onError: () => {
      setModal(false);
      message.error('Can not update admin dashboard!');
    },
  });

  const onSystemUpdate = () => {
    form.current?.validateFields().then((values) => update$.mutate(values));
  };

  return (
    <>
      <Card title="Deployment info" bodyStyle={{ padding: 0 }} className="text-xs">
        <pre className="bg-gray-100 p-3">
          <code>{JSON.stringify(info$.data, null, 2)}</code>
        </pre>
      </Card>

      <Modal
        visible={modal}
        onOk={onSystemUpdate}
        title="Update admin dashboard"
        onCancel={() => setModal(false)}
      >
        <SystemUpdateForm ref={form} />
      </Modal>
    </>
  );
}

import { AppLayout } from './AppLayout';
import { DeploymentDrillDown } from './modules/deployments/pages/DeploymentDrillDown';
import { Deployments } from './modules/deployments/pages/Deployments';
import { Envs } from './modules/system/pages/Envs';
import { OrganizationDrilldown } from './modules/organizations/pages/OrganizationDrilldown';
import { OrganizationNew } from './modules/organizations/pages/OrganizationNew';
import { Organizations } from './modules/organizations/pages/Organizations';
import { SystemDashboard } from './modules/system/pages/SystemDashboard';
import { createBrowserRouter } from 'react-router-dom';
import { Vendors } from './modules/vendors/pages/Vendors';
import { VendorsDrillDown } from './modules/vendors/pages/VendorsDrillDown';

export const router = createBrowserRouter(
  [
    {
      element: <AppLayout />,
      children: [
        {
          index: 'true',
          path: '/',
          element: <Organizations />,
        },
        {
          path: '/organizations',
          children: [
            {
              index: 'true',
              element: <Organizations />,
            },
            {
              path: ':id/:tab?',
              element: <OrganizationDrilldown />,
            },
            {
              path: 'new/:app',
              children: [
                {
                  index: 'true',
                  element: <OrganizationNew />,
                },
              ],
            },
          ],
        },
        {
          path: '/vendors',
          children: [
            {
              index: 'true',
              element: <Vendors />,
            },
            {
              path: ':id',
              element: <VendorsDrillDown />,
            },
          ],
        },
        {
          path: '/system',
          children: [
            {
              index: 'true',
              element: <SystemDashboard />,
            },
            {
              path: 'env',
              element: <Envs />,
            },
          ],
        },
        {
          path: '/deployments',
          children: [
            {
              index: 'true',
              element: <Deployments />,
            },
            {
              path: ':id/:tab?',
              element: <DeploymentDrillDown />,
            },
          ],
        },
      ],
    },
  ],
  {},
);

import { Button, Descriptions, Skeleton } from 'antd';
import { HighlightOutlined, MailOutlined } from '@ant-design/icons';

import moment from 'moment';
import { startCase } from 'lodash';

export function DeploymentSummary({
  isLoading,
  isFetched,
  release = {},
  isError,
  onUpdateDeployment,
}) {
  return (
    <>
      {isLoading && <Skeleton />}
      {isFetched && (
        <Descriptions
          title="General information"
          layout="vertical"
          bordered
          extra={[
            <Button disabled className="inline-flex items-center">
              <HighlightOutlined />
              Edit
            </Button>,
          ]}
        >
          <Descriptions.Item label="Owner">{release.ownerName}</Descriptions.Item>
          <Descriptions.Item label="Release name">{release.name}</Descriptions.Item>
          <Descriptions.Item label="Created at">
            {moment(release.createdAt).format('L LT')}
          </Descriptions.Item>
          <Descriptions.Item label="Contact email">
            <a
              className="inline-flex items-center text-blue-500"
              referrerPolicy="no-referrer"
              target="_blank"
              href={`mailto:${release.ownerEmail}`}
              rel="noreferrer"
            >
              <MailOutlined className="mr-2" />
              {release.ownerEmail}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Description">{release.description}</Descriptions.Item>
        </Descriptions>
      )}
      <br />
      {isLoading && <Skeleton />}
      {isFetched && (
        <Descriptions
          title="Deployment information"
          layout="vertical"
          bordered
          extra={[
            <Button
              disabled={isError}
              onClick={() => onUpdateDeployment(true)}
              className="inline-flex items-center"
            >
              <HighlightOutlined />
              Update deployment
            </Button>,
          ]}
        >
          <Descriptions.Item label="Chart">{release.chart}</Descriptions.Item>
          <Descriptions.Item label="Namespace">{release.namespace}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <span className="capitalize inline-flex items-center">{startCase(release.status)}</span>
          </Descriptions.Item>
        </Descriptions>
      )}
      <br />
    </>
  );
}

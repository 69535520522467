import { Button, Col, Divider, Drawer, Form, Input, Row, Select } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Activities } from '../../../containers/Activities';

import { useGetVendorActivities } from '../../../hooks/activity';
import TextArea from 'antd/lib/input/TextArea';

export const VendorForm = forwardRef(function VendorForm({ onFinish, vendor = {} }, ref) {
  const onFormSubmit = (values) => {
    return onFinish(values);
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (vendor?.id) return;

    form.setFieldsValue({
      identifier: (+new Date() * Math.random()).toString(36).substring(0, 5),
    });
  }, [form, vendor?.id]);

  return (
    <div className="rounded-lg border border-gray-400 p-5">
      <Form
        form={form}
        onFinish={onFormSubmit}
        layout="vertical"
        ref={ref}
        className="flex flex-col min-h-full"
      >
        <Form.Item name="id" initialValue={vendor?.id} hidden />
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="font-bold"
                  required
                  name="name"
                  label="Vendor name"
                  initialValue={vendor?.name}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="contactName"
                  label="Contact Name"
                  initialValue={vendor?.contactName}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="contactEmail"
                  label="Contact email"
                  initialValue={vendor?.contactEmail}
                  rules={[
                    { required: false, type: 'email' },
                    { max: 100, min: 2 },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contactPhone"
                  label="Contact Phone"
                  initialValue={vendor?.contactPhone}
                  rules={[
                    {
                      required: false,
                      message: 'Must be international format, (eg. +358501234567)',
                      pattern: new RegExp(/^\+[1-9]\d{1,14}$/), //Regex to force user to input country code in phone number (eg. +35812345)
                    },
                    { max: 50, min: 1, message: 'Maximum 50 numbers' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="website"
                  label="Website"
                  initialValue={vendor?.website}
                  rules={[
                    {
                      type: 'url',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Divider className="bg-gray-400" />

            <Row gutter={16}>
              <Col span={16}>
                <Form.Item
                  initialValue={vendor?.identifier}
                  label="Identifier"
                  tooltip="Auto generated identifier, can be edited to be up to 15 characters"
                  name="identifier"
                  className="font-bold"
                  required
                  rules={[
                    { required: true, message: 'Identifier is required' },
                    { max: 15, message: 'Identifier cannot be longer than 15 characters' },
                    { min: 5, message: 'Identifier must be at least 5 characters' },
                    {
                      pattern: /^[a-z0-9]*$/,
                      message: 'Identifier cannot contain capital letters',
                    },
                  ]}
                >
                  <Input name="identifier" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col>
          <Form.Item
            name="tags"
            label="Tags"
            className="font-bold"
            required
            rules={[{ required: true }]}
            initialValue={vendor?.tags}
          >
            <Select showSearch={false} mode="tags" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="description" label="Description" initialValue={vendor?.description}>
            <TextArea />
          </Form.Item>
        </Col>
      </Form>
    </div>
  );
});

VendorForm.Drawer = forwardRef(function VendorFormDrawer({ vendor, onDelete, ...props }, ref) {
  const form$ = useRef();
  const [item, setItem] = useState(props.vendor);

  const activities$ = useGetVendorActivities(item?.id, { enabled: !!item?.id });

  useEffect(() => {
    setItem(vendor);
  }, [vendor]);

  const close = () => setItem(null);
  const open = (item = {}) => setItem(item);

  useImperativeHandle(ref, () => ({ open, close }));

  return (
    <Drawer
      width="55%"
      onClose={close}
      destroyOnClose
      open={!!item}
      title={item?.id ? 'Edit vendor' : 'Create vendor'}
      footer={
        <div className="flex flex-row items-center">
          <div className="flex-grow">
            {item?.id ? (
              <Button onClick={() => onDelete(item)} danger>
                Delete
              </Button>
            ) : null}
          </div>
          <div className="mb-0 text-right">
            <Button onClick={close} className="mr-2" htmlType="button" disabled={props.isLoading}>
              Close
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={props.isLoading}
              onClick={() => form$.current?.submit()}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <VendorForm ref={form$} {...props} vendor={item} />

      {item?.id ? <Activities query$={activities$} /> : null}
    </Drawer>
  );
});

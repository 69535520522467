import cx from 'classnames';

const colors = {
  error: 'red-500',
  pending: 'blue-500',
  deploying: 'blue-500',
  deployed: 'green-500',
  warning: 'yellow-500',
  deleted: 'orange-500',
  'not-deleted': 'orange-500',
};

export function OrganizationDeploymentStatus({ organization, className }) {
  const color = colors[organization?.status];
  return (
    <span className={cx(`bg-${color} animate-ping rounded-full inline-flex w-1 h-1`, className)} />
  );
}

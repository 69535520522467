import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';

import { APP_SCOPES } from '../../../constants/services';
import { Button } from 'antd';
import { EnvEditor } from '../containers/EnvEditor';
import { EnvToJson } from '../components/EnvToJson';
import { EnvsTable } from '../containers/EnvsTable';
import { PageHeader } from '@ant-design/pro-layout';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { withPermissions } from '../../../context/permissions';

export const Envs = withPermissions([APP_SCOPES.ENV], function Envs() {
  const table = useRef();
  const editor = useRef();
  const converter = useRef();
  const navigate = useNavigate();

  const onBack = () => navigate.goBack();
  const onUpdateTable = () => table.current?.refetch();

  return (
    <div className="bg-white min-h-full shadow-md overflow-y-auto">
      <PageHeader
        onBack={onBack}
        title="Environments"
        className="mb-5 border-b-1 border-gray-100"
        subTitle="Manage deployment envs and container variables"
        extra={
          <div className="flex items-center flex-row">
            <Button
              onClick={() => converter.current?.open({})}
              className="mr-2 flex flex-row items-center"
            >
              .env <ArrowRightOutlined />
              {'{ }'}
            </Button>
            <Button
              onClick={() => editor.current?.open({})}
              className="flex flex-row items-center"
              icon={<PlusOutlined />}
            >
              Add new environment
            </Button>
          </div>
        }
      />
      <div className="px-5 pb-5">
        <div className="w-1/2">
          <EnvsTable ref={table} onSelect={(item) => editor.current?.open(item)} />
        </div>
      </div>

      <EnvEditor
        ref={editor}
        onCreated={onUpdateTable}
        onUpdated={onUpdateTable}
        onDeleted={onUpdateTable}
      />
      <EnvToJson.Drawer ref={converter} />
    </div>
  );
});

import { Col, Form, Row, Select } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useGetDockerImageTags, useGetDockerImages } from '../../../hooks/k8s/docker';

import { useGetSystemEnvs } from '../../../hooks/k8s/envs';

export const SystemUpdateForm = forwardRef(function SystemUpdateForm(
  { size = 'large', onFinish },
  ref,
) {
  const form = useRef();
  const [image, setImage] = useState(null);
  useImperativeHandle(ref, () => form.current);

  const envs$ = useGetSystemEnvs();
  const images$ = useGetDockerImages();

  const tags$ = useGetDockerImageTags(image, {
    enabled: !!image,
    onSuccess: ({ repository }) => form.current?.setFieldsValue({ repository }),
  });

  const onValuesChange = ({ image }) => {
    if (image) {
      setImage(image);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      validateTrigger={['onBlur', 'onSubmit']}
      onValuesChange={onValuesChange}
      layout="vertical"
      ref={form}
    >
      <Form.Item label="Environment" name="envId" required rules={[{ required: true }]}>
        <Select loading={envs$.isLoading}>
          {(envs$.data || []).map((env) => (
            <Select.Option value={env.id}>{env.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item hidden name="repository" />
          <Form.Item label="Image" name="image" required rules={[{ required: true }]}>
            <Select className="w-full" showSearch>
              {images$.data?.map((record) => (
                <Select.Option value={record.image}>{record.image}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Version" name="appVersion" required rules={[{ required: true }]}>
            <Select
              disabled={!image}
              loading={tags$.isLoading || images$.isLoading}
              name="tag"
              className="w-full"
              showSearch
            >
              {tags$.data?.tags?.map((tag) => (
                <Select.Option value={tag.name}>{tag.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

import { useMutation, useQuery } from 'react-query';
import { useService } from '../../context/services';
import { SERVICE_KEYS } from '../../constants/services';

export function useGetSystemEnvs(options = {}) {
  const service = useService(SERVICE_KEYS.ENVS);
  return useQuery('envs.system', () => service.getSystemEnvs(), { ...options });
}

export function useInspectChartEnvs(chart, options = {}) {
  const service = useService(SERVICE_KEYS.ENVS);
  return useQuery(['envs.inspect-chart', chart], () => service.inspectChart(chart), { ...options });
}

export function useUpdateSystemEnv(options = {}) {
  const service = useService(SERVICE_KEYS.ENVS);
  return useMutation((payload) => service.updateSystemEnv(payload), options);
}

export function useDeleteSystemEnv(options = {}) {
  const service = useService(SERVICE_KEYS.ENVS);
  return useMutation((payload) => service.deleteSystemEnv(payload), options);
}

export function useCreateSystemEnv(options = {}) {
  const service = useService(SERVICE_KEYS.ENVS);
  return useMutation((payload) => service.createSystemEnv(payload), options);
}

export function useInspectEnv(id, options = {}) {
  const service = useService(SERVICE_KEYS.ENVS);
  return useQuery(['envs.inspect', { id }], () => service.inspectEnv(id), {
    ...options,
    refetchOnMount: true,
  });
}

import { forwardRef } from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';

export const CreateNewKeyForm = forwardRef(function CreateNewKeyForm(_, ref) {
  return (
    <>
      <Form ref={ref}>
        <Form.Item label="Valid until" name="validity">
          <DatePicker
            disabledDate={(current) => {
              let customDate = moment().format('DD.MM.YYYY');
              return current && current < moment(customDate, 'DD.MM.YYYY');
            }}
            format={'DD.MM.YYYY'}
          />
        </Form.Item>
      </Form>
    </>
  );
});

import { useEffect, useMemo, useRef, useState } from 'react';

import { FitAddon } from '@xterm/addon-fit';
import IO from 'socket.io-client';
import { Select } from 'antd';
import { XTerm } from 'xterm-for-react';
import { useGetDeployedReleasePods } from '../../../hooks/deployments/deployments';

export function PodLogs({ release = {} }) {
  const term = useRef();
  const [pod, setPod] = useState(null);

  const fitAddon = useMemo(() => new FitAddon(), []);
  const pods$ = useGetDeployedReleasePods(release.id, {
    enabled: !!release.id,
  });

  const pods = useMemo(() => pods$.data?.pods || [], [pods$.data]);

  useEffect(() => {
    if (!term?.current?.terminal) {
      return;
    }

    if (pod) {
      term.current.terminal.clear();
      try {
        fit.fit();
      } catch (e) {
        console.debug(e);
      }
      const socket = new IO(process.env.REACT_APP_API_URL);
      socket.emit('deployment.logs', { release: release.id, pod });
      socket.on('deployment.logs', (chunk) => term.current.terminal.writeln(`${chunk.logs}\n`));

      return () => socket.disconnect();
    } else {
      term.current.terminal.writeln(`Select pod from list...\n`);
    }
  }, [pod, release, term]);

  return (
    <div>
      <div className="flex flex-row items-center mb-3">
        <Select
          placeholder="Release pods..."
          onSelect={setPod}
          value={pod}
          className="flex-grow mr-3"
        >
          {pods.map((pod) => (
            <Select.Option value={pod.name}>{pod.name}</Select.Option>
          ))}
        </Select>
      </div>
      <XTerm
        ref={term}
        addons={[fitAddon]}
        className="flex-grow"
        options={{
          windowOptions: { maximizeWin: true },
          cols: 200,
          rows: 60,
          convertEol: true,
          rendererType: 'canvas',
          allowProposedApi: true,
        }}
      />
    </div>
  );
}

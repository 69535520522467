import { Drawer } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { useGetSystemActivities } from '../../../hooks/activity';
import { Activities } from '../../../containers/Activities';

export const SystemActivitiesDrawer = forwardRef(function SystemActivitiesDrawer(props, ref) {
  const query$ = useGetSystemActivities();
  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible(!visible);
  useImperativeHandle(ref, () => ({ toggle }));

  return (
    <Drawer
      onClose={toggle}
      title="System activities"
      width="50%"
      bodyStyle={{ padding: '0 15px 0 15px' }}
      visible={visible}
    >
      <Activities title={false} query$={query$} />
    </Drawer>
  );
});

export class VendorsService {
  constructor(api) {
    this.api = api;
  }

  //Vendors
  getVendors(filters = {}) {
    return this.api.get('vendors', { params: filters }).then(({ data }) => data);
  }

  getVendorById(vendorId, filters = {}) {
    return this.api.get(`vendors/${vendorId}`, { params: filters }).then(({ data }) => data);
  }

  deleteVendor(vendorId) {
    return this.api.delete(`vendors/${vendorId}`);
  }

  addVendor(payload) {
    return this.api.post('vendors', payload);
  }

  updateVendor(vendorId, payload) {
    return this.api.patch(`vendors/${vendorId}`, payload);
  }

  getVendorKeys(vendorId) {
    return this.api.get(`vendors/${vendorId}/keys`).then(({ data }) => data);
  }

  vendorApplications(vendorId, filters = {}) {
    return this.api
      .get(`vendors/${vendorId}/applications`, { params: filters })
      .then(({ data }) => data);
  }

  createApplication(vendorId, payload) {
    return this.api.post(`vendors/${vendorId}/applications`, payload);
  }
  //

  //Vendor applications
  applicationOrganizations(applicationId, filters = {}) {
    return this.api
      .get(`applications/${applicationId}/organizations`, { params: filters })
      .then(({ data }) => data);
  }

  getAvailableOrganizations(applicationId, filters = {}) {
    return this.api
      .get(`applications/${applicationId}/available-organizations`, { params: filters })
      .then(({ data }) => data);
  }

  linkOrganization(applicationId, organizationIds) {
    return this.api
      .post(`applications/${applicationId}/organizations`, { organizationIds })
      .then(({ data }) => data);
  }

  unLinkOrganization(applicationId, organizationId) {
    return this.api
      .delete(`applications/${applicationId}/organizations`, {
        data: { organizationId },
      })
      .then(({ data }) => data);
  }

  getApplicationKeys(applicationId) {
    return this.api.get(`applications/${applicationId}/keys`).then(({ data }) => data);
  }

  deleteApplication(applicationId) {
    return this.api.delete(`applications/${applicationId}`).then(({ data }) => data);
  }

  updateApplication(applicationId, payload) {
    return this.api.patch(`applications/${applicationId}`, payload);
  }

  getApplication(applicationId) {
    return this.api.get(`applications/${applicationId}`).then(({ data }) => data);
  }
  //

  //Keys
  removeVendorKey(keyId) {
    return this.api.delete(`vendors-keys/${keyId}`).then(({ data }) => data);
  }

  addKey(vendorId, applicationId, validity) {
    return this.api
      .post(`vendors-keys`, { applicationId, vendorId, validity })
      .then(({ data }) => data);
  }
  //
}

import { message, Modal } from 'antd';
import {
  useAddKey,
  useGetKeysForVendor,
  useRemoveVendorKey,
} from '../../../hooks/vendors/vendors-management';
import moment from 'moment';
import { useRef, useState } from 'react';
import useInvalidateVendorActivityQueries from '../hooks/useInvalidateVendorActivityQueries';
import { KeysList } from '../components/KeysList';

export const VendorKeysContainer = function KeysContainer({ vendor = {} }) {
  const [createNewKeyModal, setCreateNewKeyModal] = useState(false);
  const vendorKeys = useGetKeysForVendor(vendor?.id, { enabled: !!vendor?.id });
  const invalidateVendorActivityQueries = useInvalidateVendorActivityQueries();

  const removeKey = useRemoveVendorKey({
    onSuccess: () => {
      vendorKeys.refetch();
      message.success('Key removed!');
      invalidateVendorActivityQueries();
    },
  });

  const addKey = useAddKey({
    onSuccess: () => {
      setCreateNewKeyModal(false);
      vendorKeys.refetch();
      message.success('Key created');
      invalidateVendorActivityQueries();
    },
  });

  const createNewKeyModalRef = useRef();

  const addNewKey = () => {
    createNewKeyModalRef.current?.validateFields().then((values) =>
      addKey.mutateAsync({
        vendorId: vendor.id,
        validity: values?.validity ? moment(new Date(values.validity)).format() : null,
      }),
    );
  };

  const onRemoveKey = (keyId) => {
    Modal.confirm({
      title: `Are you sure you want to delete this vendor key?`,
      okButtonProps: { danger: true },
      onOk: () => removeKey.mutateAsync({ keyId }),
      content: `The key will be permanently deleted.`,
    });
  };

  return (
    <KeysList
      title={'Vendor keys'}
      keys={vendorKeys}
      createNewKeyModal={createNewKeyModal}
      setCreateNewKeyModal={setCreateNewKeyModal}
      onRemoveKey={onRemoveKey}
      addNewKey={addNewKey}
      createNewKeyModalRef={createNewKeyModalRef}
    />
  );
};

import { useEffect, useMemo, useRef, useState } from 'react';

import { FitAddon } from '@xterm/addon-fit';
import IO from 'socket.io-client';
import { Select } from 'antd';
import { XTerm } from 'xterm-for-react';
import { useGetSystemPods } from '../../../hooks/k8s/system';

export function SystemLogs() {
  const term = useRef();
  const [pod, setPod] = useState();
  const fitAddon = useMemo(() => new FitAddon(), []);

  const systemPods = useGetSystemPods();
  const pods = useMemo(() => systemPods?.data?.pods || [], [systemPods?.data]);

  useEffect(() => {
    if (!term?.current?.terminal) {
      return;
    }

    if (pod) {
      term.current.terminal.clear();
      const socket = new IO(process.env.REACT_APP_API_URL);
      socket.emit('system.logs', { pod });
      socket.on('system.logs', (chunk) => term.current.terminal.writeln(`${chunk.logs}\n`));

      return () => socket.disconnect();
    } else {
      term.current.terminal.writeln(`Select pod from list...\n`);
    }
  }, [pod, term]);

  const extra = (
    <Select
      placeholder="Application pods..."
      showSearch
      onSelect={setPod}
      value={pod}
      className="w-96"
    >
      {pods.map((pod) => (
        <Select.Option key={pod.name} value={pod.name}>
          {pod.name}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <p className="flex-grow mr-2 text-md font-semibold">System logs</p>
        {extra}
      </div>
      <br />
      <XTerm
        ref={term}
        addons={[fitAddon]}
        className="flex-grow"
        options={{
          windowOptions: { maximizeWin: true },
          cols: 200,
          rows: 60,
          convertEol: true,
          rendererType: 'canvas',
          allowProposedApi: true,
        }}
      />
    </div>
  );
}
